import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";
import { ListsContext } from "../Contexts/ListsContext";
import { ListInterface } from "../Interfaces/ListInterface";
import { AlertContext } from "../Contexts/AlertContext";
import { CooperationContext } from "../Contexts/CooperationContext";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { getCookie } from "../Utilities/getCookie";
import { AuthContext } from "../Contexts/AuthContext";

export default function ShareModal({ listId }: any) {
  const { userDetails } = useContext(AuthContext);
  const { lists, setLists } = useContext(ListsContext);
  const [sharingLink, setSharingLink] = useState("");
  const { displayAlert } = useContext(AlertContext);
  const { setActiveEdit } = useContext(CooperationContext);
  const { t } = useTranslation();

  const currentList = lists.find((list: ListInterface) => list.id === listId);

  const handleRadioChange = (isPublic: boolean) => {
    const updatedLists = lists.map((list: ListInterface) =>
      list.id === listId
        ? { ...list, settings: { ...list.settings, public: isPublic } }
        : list
    );
    setLists(updatedLists);

    if (isPublic) {
      ReactPixel.trackCustom("SetListPublic");

      ReactGA4.event("set_list_public", {
        name: "set_list_public",
        label: "Set List Public",
        list_id: currentList.id,
      });

      // Send the event to the backend
      sendEventToBackend(
        "SetListPublic",
        window.location.href,
        "website",
        userDetails?.email,
        getCookie("_fbp") || "",
        getCookie("_fbc") || ""
      );
    }
  };

  const handleCopyLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (sharingLink) {
      navigator.clipboard
        .writeText(sharingLink)
        .then(() => {
          displayAlert("alert.link_copied", "success");
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
          displayAlert("alert.link_copy_failed", "error"); // Assuming you have an appropriate error message
        });
    }
  };

  useEffect(() => {
    // Update the sharing link whenever the listId or public setting changes
    if (currentList?.settings.public) {
      setSharingLink(`${window.location.origin}/list/${listId}`);
    } else {
      setSharingLink("");
    }
  }, [listId, currentList?.settings.public]);

  return (
    <dialog id="share_modal" className="modal">
      <form method="dialog" className="modal-box">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
        <div className="flex flex-col lg:flex-row hover-box gap-4">
          <div className="w-fit">
            <div className="form-control">
              <label className="label cursor-pointer flex gap-2">
                <span className="label-text">
                  {t("create_list.share.public")}
                </span>
                <input
                  type="radio"
                  name="radio-10"
                  className="radio checked:bg-blue-500"
                  checked={currentList?.settings.public}
                  onChange={() => handleRadioChange(true)}
                  onFocus={() =>
                    setActiveEdit({
                      type: "list",
                      id: listId,
                      fieldName: "settings.public",
                    })
                  }
                  onBlur={() =>
                    setActiveEdit({
                      type: "",
                      id: "",
                      fieldName: "",
                    })
                  }
                />
              </label>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer flex gap-2">
                <span className="label-text">
                  {t("create_list.share.private")}
                </span>
                <input
                  type="radio"
                  name="radio-10"
                  className="radio checked:bg-red-500"
                  onChange={() => handleRadioChange(false)}
                  checked={!currentList?.settings.public}
                />
              </label>
            </div>
          </div>

          {currentList?.settings.public && (
            <div>
              {t("create_list.share.url_for_share")}
              <input
                type="text"
                value={sharingLink}
                readOnly
                onClick={(e) => {
                  const target = e.target as HTMLInputElement;
                  target.select();
                }}
                className="input input-bordered w-full max-w-xs"
              />
              <button
                className="btn mt-2"
                type="button"
                onClick={handleCopyLink}
              >
                <span className="material-symbols-outlined">content_copy</span>
                {t("create_list.share.copy_link")}
              </button>
            </div>
          )}
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
