import { useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ItemInterface } from "../Interfaces/ListInterface";
import { FavoriteItemsContext } from "../Contexts/FavoriteItemsContext";
import { ListsContext } from "../Contexts/ListsContext";

export default function FavoriteItemsModal() {
  const { t } = useTranslation();

  const { listId } = useParams();

  const { favoriteItems } = useContext(FavoriteItemsContext);
  const { addItemFromFavorite, categoryId } = useContext(ListsContext);

  return (
    <dialog id="favorite_items_modal" className="modal">
      <form method="dialog" className="modal-box w-11/12 max-w-3xl h-3/4 lg:h-5/6">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
        {favoriteItems.length > 0 ? (
          <div className="flex flex-col w-full lg:min-w-[85%] items-center gap-4">
            <h2 className="text-2xl font-bold self-center">
              {t("favorite_gear.header")}
            </h2>
            <p>{t("favorite_gear.click_on_item_to_add_it_to_list")}</p>
            <div className="w-full overflow-x-auto border rounded border-base-300">
              <table className="table">
                <thead>
                  <tr>
                    <td></td>
                    <td>{t("common.name")}</td>
                    <td>{t("common.price")}</td>
                    <td>{t("common.weight")}</td>
                    <td>{t("common.quantity")}</td>
                  </tr>
                </thead>
                <tbody>
                  {favoriteItems.map((item: ItemInterface) => {
                    return (
                      <tr
                        key={item.id}
                        className="hover cursor-pointer"
                        onClick={() =>
                          addItemFromFavorite(listId, categoryId, item)
                        }
                      >
                        <td>
                          <span className="material-symbols-outlined">
                            add_circle
                          </span>
                        </td>
                        <td>{item.name}</td>
                        <td>{item.price}</td>
                        <td>{item.weight}</td>
                        <td>{item.quantity}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>
            <p>
              {t("favorite_gear.you_dont_have_any_favorite_items")}{" "}
              {t("favorite_gear.add_by_clicking_star")}{" "}
              <span className="material-symbols-outlined">star</span>{" "}
              {t("favorite_gear.near_item_while_creating_list")}
            </p>
          </div>
        )}
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
