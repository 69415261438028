import { useEffect, useState, useContext } from "react";
import { ListInterface } from "../Interfaces/ListInterface";
import { useTranslation } from "react-i18next";
import { ListsContext } from "../Contexts/ListsContext";

export default function PackingProgressBar({
  currentList,
}: {
  currentList: ListInterface;
}) {
  const { t } = useTranslation();

  const { uncheckAll } = useContext(ListsContext);

  const [totalItems, setTotalItems] = useState(0);
  const [checkedItems, setCheckedItems] = useState(0);

  useEffect(() => {
    let newTotalItems: number = 0;
    let newCheckedItems: number = 0;

    currentList.categories.forEach((category) => {
      newTotalItems += category.items.length;
      newCheckedItems += category.items.filter((item) => item.checked).length;
    });

    setTotalItems(newTotalItems);
    setCheckedItems(newCheckedItems);
  }, [currentList]);

  const progressPercentage =
    totalItems > 0 ? (checkedItems / totalItems) * 100 : 0;

  const progressbarBackgroundColor =
    progressPercentage === 100
      ? "#0AA631"
      : progressPercentage > 50
      ? "#06A1C4"
      : "#FF5757";

  const handleResetButtonClick = () => {
    setCheckedItems(0);
    uncheckAll(currentList.id);
  };

  return (
    <div className="flex flex-col items-center w-full lg:col-start-1 lg:col-end-3 lg:justify-self-center lg:w-1/2 gap-2">
      {`${t("create_list.packed")} ${checkedItems} ${t(
        "create_list.item_s"
      )} ${t(
        "common.of"
      )} ${totalItems}`}
      <div className="w-full bg-base-300 rounded-lg text-base-100">
        <div
          className="progressbar-complete"
          style={{
            width: `${progressPercentage}%`,
            backgroundColor: progressbarBackgroundColor,
          }}
        >
          {progressPercentage > 10 ? `${progressPercentage.toFixed(0)}%` : ""}
        </div>
      </div>
      {progressPercentage > 0 ? (
        <button
          className="btn btn-sm btn-outline btn-error"
          onClick={handleResetButtonClick}
        >
          {t("create_list.reset_checklist_progressbar")}
        </button>
      ) : (
        ""
      )}
      <div className="font-bold">
        {progressPercentage === 100 ? `${t("create_list.ready_for_hike")}` : ""}
      </div>
    </div>
  );
}
