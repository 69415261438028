export const convertWeight = (weightInGrams: number, unit: string) => {
  if (typeof weightInGrams !== "number") {
    console.error("weightInGrams is not a number:", weightInGrams);
    return "Error"; // or some default value
  }
  switch (unit) {
    case "kg":
      return (weightInGrams / 1000).toFixed(2);
    case "lb":
      return (weightInGrams / 453.592).toFixed(2);
    case "oz":
      return (weightInGrams / 28.3495).toFixed(2);
    default:
      return weightInGrams.toFixed(2); // Assuming 'g' as default
  }
};
