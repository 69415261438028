import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactGA4 from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import axios from "axios";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { getCookie } from "../Utilities/getCookie";
import { AuthContext } from "../Contexts/AuthContext";

export default function Register() {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;

  const { facebookAuth } = useContext(AuthContext);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (password !== repeatPassword) {
      setError(`${t("access.password_mismatch")}`);
      return;
    }

    // Check for minimum password length
    if (password.length < 8) {
      setError(`${t("access.password_too_short")}`);
      return;
    }

    // Reset error and success messages
    setError("");
    setSuccess("");

    axios
      .post(`${API_URL}/register`, {
        email: email,
        password: password,
        terms: terms,
        newsletter: newsletter,
      })
      .then(function () {
        setSuccess(`${t("access.registered_successfully")}`);

        // Tracking events
        ReactPixel.track("CompleteRegistration");

        ReactGA4.event("signup", {
          name: "signup",
          label: "Signup",
        });

        // Send to backend
        sendEventToBackend(
          "CompleteRegistration",
          window.location.href,
          "website",
          email,
          getCookie("_fbp") || "",
          getCookie("_fbc") || ""
        );
      })
      .catch(function (error) {
        if (error.response.status === 409) {
          setError(`${t(`access.email_exists`)}`);
        } else {
          setError(`${t("access.network_error")}`);
        }
      });
  };

  return (
    <div className="flex flex-col gap-4">
      {success && (
        <div className="alert alert-success">
          <span>{success}</span>
        </div>
      )}
      {error && (
        <div className="alert alert-error">
          <span>{error}</span>
        </div>
      )}
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <input
          type="email"
          id="email"
          name="email"
          placeholder={t("access.your_email") || ""}
          className="input input-bordered w-full"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          id="password"
          name="password"
          placeholder={t("access.your_password") || ""}
          className="input input-bordered w-full"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          id="repeat-password"
          name="repeat-password"
          placeholder={t("access.repeat_password") || ""}
          className="input input-bordered w-full"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          required
        />
        <div className="flex flex-col gap-2">
          <div className="flex">
            <input
              className="checkbox"
              type="checkbox"
              id="terms"
              name="terms"
              value="terms"
              required
              onChange={() => setTerms(!terms)}
            />
            <label htmlFor="terms" className="pl-2">
              {t("common.accept")}{" "}
              <button
                type="button"
                className="link lowercase"
                onClick={() => window.terms_and_conditions_modal.showModal()}
              >
                {t("common.terms_and_conditions")}
              </button>
            </label>
          </div>
          <div className="flex">
            <input
              className="checkbox"
              type="checkbox"
              id="newsletter"
              name="newsletter"
              value="newsletter"
              onChange={() => setNewsletter(!newsletter)}
            />
            <label htmlFor="newsletter" className="pl-2">
              {t("access.sign_to_newsletter")}
            </label>
          </div>
        </div>
        <button className="btn btn-accent" type="submit" value="Register">
          {t("access.register")}
        </button>
        {/* <div className="divider">{t("common.or")}</div>
        <button className="btn btn-info" type="button" onClick={facebookAuth}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            viewBox="0 0 512 512"
          >
            <path
              fill="#FFFFFF"
              d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
            />
          </svg>
          {t("access.signup_with_facebook")}
        </button> */}
      </form>
    </div>
  );
}
