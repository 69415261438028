import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { ItemInterface } from "../Interfaces/ListInterface";
import { AlertContext } from "./AlertContext";
import { AuthContext } from "./AuthContext";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { getCookie } from "../Utilities/getCookie";
import { useInitialList } from "../Utilities/initialList";
import { calculateWeightInGrams } from "../Utilities/calculateWeightInGrams";

export const GlobalItemsContext = createContext<any>(null);

export const GlobalItemsProvider = ({ children }: { children: ReactNode }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [globalItems, setGlobalItems] = useState<ItemInterface[]>([]);
  const [globalItemsQuantity, setGlobalItemsQuantity] = useState(0);
  const [globalItemsAreLoading, setGlobalItemsAreLoading] = useState(true);

  const { authenticated, userDetails } = useContext(AuthContext);

  const { displayAlert } = useContext(AlertContext);
  const { initialItem } = useInitialList();

  const addItemToGlobalItems = async (item: ItemInterface) => {
    //Fields that must be filled out
    const requiredFields = [
      "id",
      "type",
      "brand",
      "name",
      "weight",
      "weightUnit",
      "weightInGrams",
    ];

    // Check if all fields are set
    for (let key of requiredFields) {
      if (
        item[key as keyof ItemInterface] === null ||
        item[key as keyof ItemInterface] === undefined ||
        item[key as keyof ItemInterface] === ""
      ) {
        displayAlert(
          "alert.fields_type_brand_name_weight_must_be_filled",
          "error"
        );
        return;
      }
    }

    // Deep copy the item
    const newItem: ItemInterface = JSON.parse(JSON.stringify(item));

    // Assign a new ID to the copied item
    newItem.id = uuidv4();

    try {
      await axios.post(`${API_URL}/global-item/`, newItem, {
        withCredentials: true,
      });
      displayAlert("alert.item_added_to_global_items", "success");
    } catch (error) {
      displayAlert("alert.error_while_adding_item_to_global_items", "error");
    }

    ReactPixel.trackCustom("AddGlobalItem");

    ReactGA4.event("add_global_item", {
      name: "add_global_item",
      label: "Add Global Item",
    });

    // Send the event to the backend
    sendEventToBackend(
      "AddGlobalItem",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  };

  const addGlobalItem = () => {
    const newGlobalItem = {
      ...initialItem,
      id: uuidv4(),
      ownerId: userDetails.id,
      saved: true,
    };

    setGlobalItems((prevItems) => [newGlobalItem, ...prevItems]);
  };

  const changeGlobalItemInfo = (itemId: string, key: string, value: any) => {
    setGlobalItems(
      globalItems.map((item) =>
        item.id === itemId ? { ...item, [key]: value } : item
      )
    );
  };

  const changeGlobalItemWeight = (
    itemId: string,
    field: string,
    value: any
  ) => {
    setGlobalItems(
      globalItems.map((item) => {
        if (item.id === itemId) {
          let unit = field === "weightUnit" ? value : item.weightUnit;
          let weight = field === "weight" ? Number(value) : Number(item.weight);

          if (isNaN(weight)) {
            weight = 0;
          }

          const weightInGrams = calculateWeightInGrams(weight, unit);
          return { ...item, weightInGrams, [field]: value };
        }
        return item;
      })
    );
  };

  const saveGlobalItem = async (item: ItemInterface) => {
    //Fields that must be filled out
    const requiredFields = [
      "id",
      "type",
      "brand",
      "name",
      "weight",
      "weightUnit",
      "weightInGrams",
    ];

    // Check if all fields are set
    for (let key of requiredFields) {
      if (
        item[key as keyof ItemInterface] === null ||
        item[key as keyof ItemInterface] === undefined ||
        item[key as keyof ItemInterface] === ""
      ) {
        displayAlert(
          "alert.fields_type_brand_name_weight_must_be_filled",
          "error"
        );
        return;
      }
    }

    // Deep copy the item
    const newItem: ItemInterface = JSON.parse(JSON.stringify(item));

    try {
      await axios.post(`${API_URL}/global-item/`, newItem, {
        withCredentials: true,
      });
      displayAlert("alert.global_item_changes_saved", "success");
    } catch (error) {
      displayAlert("alert.error_while_adding_item_to_global_items", "error");
    }

    ReactPixel.trackCustom("SaveGlobalItem");

    ReactGA4.event("save_global_item", {
      name: "save_global_item",
      label: "Save Global Item",
    });

    // Send the event to the backend
    sendEventToBackend(
      "SaveGlobalItem",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  };

  const deleteGlobalItem = async (id: string) => {
    // Send DELETE request to server
    try {
      const response = await axios.delete(`${API_URL}/global-items/${id}`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        // Remove from local state
        setGlobalItems((prevItems) => {
          return prevItems.filter((item) => item.id !== id);
        });
      }
    } catch (error) {
      console.error("Error deleting favorite item from the database:", error);
    }
  };

  const fetchGlobalItemsCount = useCallback(async () => {
    if (!authenticated) return;

    try {
      const response = await axios.get(`${API_URL}/global-items/count`, {
        withCredentials: true,
      });
      setGlobalItemsQuantity(response.data.count);
    } catch (error) {
      console.error("Error fetching global items count:", error);
    }
  }, [authenticated, API_URL]);

  const fetchGlobalItems = useCallback(async () => {
    if (!authenticated) return;
    // Indicate loading start
    setGlobalItemsAreLoading(true);
    try {
      const response = await axios.get(`${API_URL}/global-items/all`, {
        withCredentials: true,
      });
      setGlobalItems(response.data);
    } catch (error) {
      console.error("Error fetching global items:", error);
    } finally {
      setGlobalItemsAreLoading(false);
    }
  }, [authenticated, API_URL]);

  return (
    <GlobalItemsContext.Provider
      value={{
        globalItems,
        setGlobalItems,
        addItemToGlobalItems,
        globalItemsAreLoading,
        setGlobalItemsAreLoading,
        globalItemsQuantity,
        fetchGlobalItems,
        fetchGlobalItemsCount,
        deleteGlobalItem,
        addGlobalItem,
        changeGlobalItemInfo,
        changeGlobalItemWeight,
        saveGlobalItem,
      }}
    >
      {children}
    </GlobalItemsContext.Provider>
  );
};
