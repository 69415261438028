export const convertUnitToAbbreviation = (unit: string) => {
  switch (unit.toLowerCase()) {
    case "pound":
      return "lb";
    case "ounce":
      return "oz";
    case "gram":
      return "g";
    case "kilogram":
      return "kg";
    default:
      throw new Error(`Unsupported unit: ${unit}`);
  }
};