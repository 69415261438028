import { useTranslation } from "react-i18next";

export default function HelpSection() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full items-center gap-2 p-2 lg:p-8">
      <h2 className="text-2xl font-bold mb-2">{t("help.how_it_works")}</h2>
      <div className="collapse collapse-arrow bg-base-200">
        <input type="radio" name="my-accordion-1" defaultChecked />
        <div className="collapse-title text-xl font-medium">
          {t("help.how_it_works")}
        </div>
        <div className="collapse-content">
          <ol className="list-decimal ml-4">
            <li>{t("help.click_on_fields_to_edit_them")}</li>
            <li>{t("help.add_categories_and_weight_to_generate_chart")}</li>
            <li>{t("help.when_you_finish_share_list")}</li>
          </ol>
        </div>
      </div>
      <div className="collapse collapse-arrow bg-base-200">
        <input type="radio" name="my-accordion-1" />
        <div className="collapse-title text-xl font-medium">
          {t("help.available_functions")}
        </div>
        <div className="collapse-content h-full">
          <ol className="list-decimal ml-4">
            <li>{t("help.share_list")}</li>
            <li>{t("help.edit_list_in_cooperation")}</li>
            <li>{t("help.import_list")}</li>
            <li>{t("help.export_list")}</li>
            <li>{t("help.items_price")}</li>
            <li>{t("help.favorite_items")}</li>
            <li>{t("help.worn_items")}</li>
            <li>{t("help.consumable_items")}</li>
            <li>{t("help.checklist")}</li>
          </ol>
        </div>
      </div>
      <div className="collapse collapse-arrow bg-base-200">
        <input type="radio" name="my-accordion-1" />
        <div className="collapse-title text-xl font-medium">
          {t("help.share_list")}:
        </div>
        <div className="collapse-content">
          <ol className="list-decimal ml-4">
            <li>{t("help.click_share_while_editing_list")}</li>
            <li>{t("help.set_list_as_public")}</li>
            <li>{t("help.share_link_with_others")}</li>
          </ol>
        </div>
      </div>
      <div className="collapse collapse-arrow bg-base-200">
        <input type="radio" name="my-accordion-1" />
        <div className="collapse-title text-xl font-medium">
          {t("help.edit_list_in_cooperation")}:
        </div>
        <div className="collapse-content">
          <p>{t("help.edit_list_in_cooperation_paragraph")}</p>
        </div>
      </div>
      <div className="collapse collapse-arrow bg-base-200">
        <input type="radio" name="my-accordion-1" />
        <div className="collapse-title text-xl font-medium">
          {t("help.weight_of_worn_items")}
        </div>
        <div className="collapse-content">
          <p>{t("help.weight_of_worn_items_paragraph")}</p>
        </div>
      </div>
      <div className="collapse collapse-arrow bg-base-200">
        <input type="radio" name="my-accordion-1" />
        <div className="collapse-title text-xl font-medium">
          {t("help.favorite_items")}:
        </div>
        <div className="collapse-content">
          <ol className="list-decimal ml-4">
            <li>{t("help.add_item_to_favorite_clicking_star")}</li>
            <li>
              {t("help.add_items_to_lists_by_clicking_choose_from_favorite")}
            </li>
            <li>{t("help.to_edit_favorite_item_delete")}</li>
          </ol>
        </div>
      </div>
      <div className="collapse collapse-arrow bg-base-200">
        <input type="radio" name="my-accordion-1" />
        <div className="collapse-title text-xl font-medium">
          {t("help.why_cant_check_worn_and_consumable")}
        </div>
        <div className="collapse-content">
          <p>{t("help.why_cant_check_worn_and_consumable_paragraph")}</p>
        </div>
      </div>

      <p>{t("help.have_more_questions_send_email")} hello@hikepack.app</p>
    </div>
  );
}
