import { createContext, useState, ReactNode } from "react";

export const AlertContext = createContext<any>(null);

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success'); // 'success' or 'error'

  const displayAlert = (message: string, type: string) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, 3000);
  }

  return (
    <AlertContext.Provider
      value={{ showAlert, alertMessage, alertType, displayAlert }}
    >
      {children}
    </AlertContext.Provider>
  );
};

