import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ItemInterface } from "../Interfaces/ListInterface";
import { GlobalItemsContext } from "../Contexts/GlobalItemsContext";
import GlobalItemsSearch from "../Components/GlobalItemsSearch";
import GlobalItem from "../Components/GlobalItem";

export default function FavoriteItemsModal() {
  const { t } = useTranslation();

  const { globalItems, globalItemsAreLoading, globalItemsQuantity } =
    useContext(GlobalItemsContext);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = globalItems.slice(firstItemIndex, lastItemIndex);

  const totalPages = Math.ceil(globalItems.length / itemsPerPage);

  return (
    <dialog id="global_items_modal" className="modal">
      <form
        method="dialog"
        className="modal-box w-11/12 max-w-3xl h-3/4 lg:h-5/6"
      >
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
        <div className="flex flex-col w-full lg:min-w-[85%] items-center gap-4">
          <h2 className="text-2xl font-bold self-center">
            {t("global_items.header")}
          </h2>
          <p>{t("global_items.click_on_item_to_add_it_to_list")}</p>
          <div className="w-full border rounded border-base-300">
            <table className="table">
              <thead>
                <GlobalItemsSearch />
              </thead>
              <tbody>
                {/* rows */}
                {globalItemsAreLoading ? (
                  <tr>
                    <td colSpan={4} className="flex items-center gap-2">
                      <span className="loading loading-spinner text-success"></span>
                      <span>{t("common.searching")}</span>
                    </td>
                  </tr>
                ) : globalItems.length === 0 ? (
                  <tr>
                    <td>{t("common.no_items_found")}</td>
                  </tr>
                ) : (
                  currentItems.map((item: ItemInterface) => {
                    return (
                      <GlobalItem key={item.id} item={item} inModal={true} />
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="flex items-center gap-2">
            <button
              className="btn"
              disabled={currentPage <= 1}
              type="button"
              onClick={() => setCurrentPage((prev) => prev - 1)}
            >
              {t("common.previous")}
            </button>
            <span>
              {t("common.page")} {currentPage} {t("common.of")} {totalPages}
            </span>
            <button
              className="btn"
              disabled={currentPage >= totalPages}
              type="button"
              onClick={() => setCurrentPage((prev) => prev + 1)}
            >
              {t("common.next")}
            </button>
          </div>
          <span>
            {t("global_items.all_items_in_base")} {globalItemsQuantity}
          </span>
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
