import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ItemInterface } from "../Interfaces/ListInterface";
import { GlobalItemsContext } from "../Contexts/GlobalItemsContext";
import GlobalItem from "./GlobalItem";
import GlobalItemsSearch from "./GlobalItemsSearch";

export default function GlobalItemsSection() {
  const { t } = useTranslation();

  const {
    globalItems,
    globalItemsAreLoading,
    globalItemsQuantity,
    fetchGlobalItemsCount,
    addGlobalItem,
  } = useContext(GlobalItemsContext);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = globalItems.slice(firstItemIndex, lastItemIndex);

  const totalPages = Math.ceil(globalItems.length / itemsPerPage);

  const handleAddGlobalItemClick = () => {
    setCurrentPage(1);
    addGlobalItem();
  };

  useEffect(() => {
    fetchGlobalItemsCount();
  }, [fetchGlobalItemsCount, addGlobalItem]);

  return (
    <section className="flex flex-col items-center w-full gap-4 p-2 lg:p-8 overflow-auto">
      <h2 className="text-2xl font-bold self-center">
        {t("global_items.header")}
      </h2>
      <div className="w-full border rounded border-base-300 overflow-auto">
        <table className="table">
          <thead>
            <GlobalItemsSearch />
          </thead>
          <tbody>
            {/* rows */}
            {globalItemsAreLoading ? (
              <tr>
                <td colSpan={4} className="flex items-center gap-2">
                  <span className="loading loading-spinner text-success"></span>
                  <span>{t("common.searching")}</span>
                </td>
              </tr>
            ) : globalItems.length === 0 ? (
              <tr>
                <td>{t("common.no_items_found")}</td>
              </tr>
            ) : (
              currentItems.map((item: ItemInterface) => {
                return <GlobalItem key={item.id} item={item} inModal={false} />;
              })
            )}
          </tbody>
        </table>
      </div>
      <button
        onClick={() => handleAddGlobalItemClick()}
        className="btn flex-nowrap self-start"
      >
        <span className="material-symbols-outlined">add_circle</span>
        {t("global_items.add_global_item")}
      </button>
      <div className="flex items-center gap-2">
        <button
          className="btn"
          disabled={currentPage <= 1}
          type="button"
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          {t("common.previous")}
        </button>
        <span>
          {t("common.page")} {currentPage} {t("common.of")} {totalPages}
        </span>
        <button
          className="btn"
          disabled={currentPage >= totalPages}
          type="button"
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          {t("common.next")}
        </button>
      </div>
      <span>
        {t("global_items.all_items_in_base")} {globalItemsQuantity}
      </span>
    </section>
  );
}
