import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FavoriteItemsContext } from "../Contexts/FavoriteItemsContext";
import { ItemInterface } from "../Interfaces/ListInterface";
import { GlobalItemsContext } from "../Contexts/GlobalItemsContext";

export default function FavoriteItem({ item }: { item: ItemInterface }) {
  const { t } = useTranslation();

  const {
    deleteFavoriteItem,
    changeFavoriteItemInfo,
    changeFavoriteItemWeight,
    changeFavoriteItemBooleanField,
  } = useContext(FavoriteItemsContext);
  const { addItemToGlobalItems } = useContext(GlobalItemsContext);

  const isItemWorn = item.worn;
  const isItemConsumable = item.consumable;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    changeFavoriteItemInfo(item.id, e.target.name, e.target.value);
  };

  const handleWeightChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    changeFavoriteItemWeight(item.id, e.target.name, e.target.value);
  };

  const handleButtonClick = (field: string) => () => {
    changeFavoriteItemBooleanField(item.id, field);
  };

  const handleAddItemToGlobalItemsClick = () => {
    addItemToGlobalItems(item);
  };

  const handleRemoveFavoriteItem = () => {
    if (window.confirm(`${t("delete_confirmation.favorite_item")}`)) {
      deleteFavoriteItem(item.id);
    }
  };

  //checking input
  const isNumeric = (str: string) => {
    return /^\d*(\.\d+)?$/.test(str);
  };
  
  return (
    <tr className="hover">
      <td>
        <input
          name="type"
          className="input input-ghost w-full max-w-xs"
          placeholder={t("create_list.item_type") || ""}
          maxLength={255}
          value={item.type}
          onChange={(e) => handleChange(e)}
        />
      </td>
      <td>
        <input
          type="text"
          name="brand"
          className="input input-ghost w-full max-w-xs"
          placeholder={t("create_list.item_brand") || ""}
          maxLength={255}
          value={item.brand}
          onChange={(e) => handleChange(e)}
        />
      </td>
      <td>
        <input
          type="text"
          name="name"
          className="input input-ghost w-full max-w-xs"
          placeholder={t("create_list.item_name") || ""}
          maxLength={255}
          value={item.name}
          onChange={(e) => handleChange(e)}
        />
      </td>
      <td>
        <button
          title={t("create_list.worn") || ""}
          className={`material-symbols-outlined item-buttons ${
            isItemWorn ? "active-item-button" : ""
          }`}
          onClick={handleButtonClick("worn")}
        >
          laundry
        </button>
      </td>
      <td>
        <button
          title={t("create_list.consumable") || ""}
          className={`material-symbols-outlined item-buttons ${
            isItemConsumable ? "active-item-button" : ""
          }`}
          onClick={handleButtonClick("consumable")}
        >
          restaurant
        </button>
      </td>
      <td>
        <button
          title={t("create_list.add_to_global_items") || ""}
          className="material-symbols-outlined star-button"
          onClick={() => handleAddItemToGlobalItemsClick()}
        >
          playlist_add
        </button>
      </td>
      <td>
        <input
          type="number"
          inputMode="decimal"
          name="price"
          className="input input-ghost min-w-[3.5rem] w-full max-w-xs"
          placeholder={t("common.price") || ""}
          value={item.price}
          onChange={(e) => {
            const value = e.target.value;
            if (isNumeric(value) && value.toString().length <= 8) {
              handleChange(e);
            }
          }}
        />
      </td>
      <td>
        <section className="flex">
          <input
            type="number"
            inputMode="decimal"
            name="weight"
            className="input input-ghost min-w-[3.5rem] w-full max-w-xs"
            placeholder={t("common.weight") || ""}
            value={item.weight}
            onChange={(e) => {
              const value = e.target.value;
              if (isNumeric(value) && value.toString().length <= 8) {
                handleWeightChange(e);
              }
            }}
          />
          <select
            name="weightUnit"
            className="input input-ghost w-16 max-w-[4rem] p-0 cursor-pointer"
            value={item.weightUnit}
            onChange={(e) => handleWeightChange(e)}
          >
            <option value="g">{t("weight_units.g")}</option>
            <option value="kg">{t("weight_units.kg")}</option>
            <option value="lb">{t("weight_units.lb")}</option>
            <option value="oz">{t("weight_units.oz")}</option>
          </select>
        </section>
      </td>
      <td>
        <input
          type="number"
          inputMode="decimal"
          name="quantity"
          className="input input-ghost w-24 max-w-[6rem]"
          placeholder={t("common.quantity") || ""}
          value={item.quantity}
          onChange={(e) => {
            const value = e.target.value;
            if (isNumeric(value) && value.toString().length <= 8) {
              handleChange(e);
            }
          }}
        />
      </td>
      <td>
        <button
          onClick={handleRemoveFavoriteItem}
          className="material-symbols-outlined delete-button"
        >
          delete
        </button>
      </td>
    </tr>
  );
}
