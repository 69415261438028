import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ListsContext } from "../Contexts/ListsContext";
import { ListInterface } from "../Interfaces/ListInterface";
import { convertWeight } from "../Utilities/convertWeight";
import {
  calculateCategoryTotalPrice,
  calculateCategoryTotalWeight,
  calculateListTotalPrice,
  calculateListTotalWeight,
  calculateTotalConsumableWeight,
  calculateTotalWornWeight,
} from "../Utilities/calculateTotals";
import { formatCurrency } from "../Utilities/formatCurrency";

export default function Summary({
  currentList,
  setCurrentList,
  forGuest,
}: {
  currentList: ListInterface;
  setCurrentList: any;
  forGuest: boolean;
}) {
  const { t } = useTranslation();

  const { updateSummaryWeightUnit } = useContext(ListsContext);

  const categoriesData = currentList ? currentList.categories : [];

  //Show or hide worn/consumable items and price
  const showPrice = currentList ? currentList.settings.showPrice : false;
  const wornItems = currentList ? currentList.settings.wornItems : false;
  const consumableItems = currentList
    ? currentList.settings.consumableItems
    : false;

  // Extract the currency setting from the current list
  const currency = currentList ? currentList.settings.currency : "EUR";

  const handleChangeSummaryWeightUnit = (summaryWeightUnit: string) => {
    if (!forGuest) {
      updateSummaryWeightUnit(currentList.id, summaryWeightUnit);
    } else if (setCurrentList) {
      setCurrentList({
        ...currentList,
        settings: { ...currentList.settings, summaryWeightUnit },
      });
    }
  };

  return (
    <div className="lg:justify-self-start">
      <table className="table">
        {/* head */}
        <thead>
          <tr>
            <th>{t("common.category")}</th>
            {showPrice && <th>{t("common.price")}</th>}
            <th>{t("common.weight")}</th>
          </tr>
        </thead>
        <tbody>
          {/* rows */}
          {categoriesData.map((category: any) => {
            return (
              <tr key={category.id} className="hover">
                <td className="hide-overflow">{category.name}</td>
                {showPrice && (
                  <td>
                    {formatCurrency(
                      calculateCategoryTotalPrice(category),
                      currency
                    )}
                  </td>
                )}
                <td>
                  {`${convertWeight(
                    calculateCategoryTotalWeight(category),
                    currentList.settings.summaryWeightUnit
                  )} 
              ${t(`weight_units.${currentList.settings.summaryWeightUnit}`)}
                  `}
                </td>
              </tr>
            );
          })}
          {wornItems || consumableItems ? (
            <tr>
              <td>{t("create_list.in_this")}</td>
            </tr>
          ) : (
            ""
          )}
          {wornItems && (
            <tr className="hover">
              <td>{t("common.worn")}: </td>
              {showPrice && <td></td>}
              <td>
                {`${convertWeight(
                  calculateTotalWornWeight(currentList),
                  currentList.settings.summaryWeightUnit
                )} ${t(
                  `weight_units.${currentList.settings.summaryWeightUnit}`
                )}`}
              </td>
            </tr>
          )}
          {consumableItems && (
            <tr className="hover">
              <td>{t("common.consumable")}: </td>
              {showPrice && <td></td>}
              <td>
                {`${convertWeight(
                  calculateTotalConsumableWeight(currentList),
                  currentList.settings.summaryWeightUnit
                )} ${t(
                  `weight_units.${currentList.settings.summaryWeightUnit}`
                )}`}
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td>{t("common.sum")}</td>
            {showPrice && (
              <td>
                {formatCurrency(calculateListTotalPrice(currentList), currency)}
              </td>
            )}
            <td>
              {`${convertWeight(
                calculateListTotalWeight(currentList),
                currentList.settings.summaryWeightUnit
              )}`}
              <select
                name="summaryWeightUnit"
                className="select w-fit max-w-fit ml-1 pr-8"
                value={
                  currentList ? currentList.settings.summaryWeightUnit : ""
                }
                onChange={(event) =>
                  currentList &&
                  handleChangeSummaryWeightUnit(event.target.value)
                }
              >
                <option value="g">{t("weight_units.g")}</option>
                <option value="kg">{t("weight_units.kg")}</option>
                <option value="lb">{t("weight_units.lb")}</option>
                <option value="oz">{t("weight_units.oz")}</option>
              </select>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
