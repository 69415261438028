import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    // Normalize the detected language code after initialization
    const normalizedLanguage = i18n.language.split('-')[0];
    if (normalizedLanguage !== i18n.language) {
      i18n.changeLanguage(normalizedLanguage);
    }
  });

export default i18n;