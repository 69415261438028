import { useTranslation } from "react-i18next";
import logo from "../Assets/logo/logo.png";
import LandingPageForm from "./LandingPageForm";
import FeaturesSection from "./FeaturesSection";
import Footer from "./Footer";

export default function LandingPage() {
  const { t } = useTranslation();

  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="flex flex-col items-center w-full h-fit">
      <div className="flex flex-col items-center w-full h-full min-h-screen bg-landingpageMobile sm:bg-landingpageDesktop bg-cover bg-top relative">
        <img
          src={logo}
          alt="HikePack logo"
          className="h-32 lg:h-32 mt-24 lg:mt-40 mb-2"
        />
        <div className="h-[500px] relative">
          <LandingPageForm />
        </div>
        <button
          className="flex items-center justify-center lg:text-lg text-white absolute bottom-14 lg:bottom-[1rem] w-full"
          onClick={handleScroll}
        >
          <span className="material-symbols-outlined">
            keyboard_double_arrow_down
          </span>
          <span>
            {t("landing_page.scrool_down_to_find_what_its_all_about")}
          </span>
          <span className="material-symbols-outlined">
            keyboard_double_arrow_down
          </span>
        </button>
      </div>
      <FeaturesSection />
      <Footer />
    </div>
  );
}
