import PrivacyPolicyText from "../Components/PrivacyPolicyText";

export default function PrivacyPolicyModal() {
  return (
    <dialog id="privacy_policy_modal" className="modal">
      <form method="dialog" className="modal-box w-11/12 max-w-3xl">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
        <PrivacyPolicyText />
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
