import appScreen from "../Assets/images/app_screen.jpg";
import { useTranslation } from "react-i18next";

export default function FeaturesSection() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center w-3/4 gap-20 mt-8 mb-16">
      <div className="flex flex-col items-center gap-4">
        <h2 className="uppercase underline text-center text-2xl font-bold">
          {t("landing_page.main_header")}
        </h2>
        <div className="flex flex-col items-center gap-8 lg:flex-row">
          <img src={appScreen} alt="screen" className="w-fit lg:w-1/2 h-fit" />
          <p className="text-center">
            {t("landing_page.main_paragraph_1")}
            <br />
            <br />
            {t("landing_page.main_paragraph_2")}
          </p>
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-evenly gap-8 lg:flex-row lg:gap-0">
        <div className="flex flex-col items-center lg:w-1/4">
          <span className="material-symbols-outlined text-[2.5rem]">
            weight
          </span>
          <h2 className="uppercase underline text-center font-bold">
            {t("landing_page.feature_header_1")}
          </h2>
          <p className="text-center">{t("landing_page.feature_paragraph_1")}</p>
        </div>
        <div className="flex flex-col items-center lg:w-1/4">
          <span className="material-symbols-outlined text-[2.5rem]">
            psychology_alt
          </span>
          <h2 className="uppercase underline text-center font-bold">
            {t("landing_page.feature_header_2")}
          </h2>
          <p className="text-center">{t("landing_page.feature_paragraph_2")}</p>
        </div>
        <div className="flex flex-col items-center lg:w-1/4">
          <span className="material-symbols-outlined text-[2.5rem]">share</span>
          <h2 className="uppercase underline text-center font-bold">
            {t("landing_page.feature_header_3")}
          </h2>
          <p className="text-center">{t("landing_page.feature_paragraph_3")}</p>
        </div>
      </div>
    </div>
  );
}
