import { createContext, useState, ReactNode, useEffect } from "react";

export const ThemeContext = createContext<any>(null);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  // Try to load theme preference from localStorage or detect from browser settings
  const [isDark, setIsDark] = useState(() => {
    const storedThemePreference = window.localStorage.getItem("isDark");
    if (storedThemePreference !== null) {
      // Use saved theme preference from localStorage if available
      return JSON.parse(storedThemePreference);
    } else {
      // Detect if user has set their system to use a dark color theme
      const userPrefersDark =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches;
      return userPrefersDark;
    }
  });

  useEffect(() => {
    // Persist theme preference in localStorage
    window.localStorage.setItem("isDark", JSON.stringify(isDark));

    // Apply theme preference to the document
    document.documentElement.setAttribute(
      "data-theme",
      isDark ? "dark" : "light"
    );
  }, [isDark]); // Re-run effect when `isDark` changes

  return (
    <ThemeContext.Provider value={{ isDark, setIsDark }}>{children}</ThemeContext.Provider>
  );
};
