import React, { createContext, useState, ReactNode } from "react";

export const AuthContext = createContext<any>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const API_URL = process.env.REACT_APP_API_URL;

  const [authenticated, setAuthenticated] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [isAuthorizing, setIsAuthorizing] = useState(true);
  const [isListOwner, setIsListOwner] = useState(null);

  const facebookAuth = () => {
    window.location.href = `${API_URL}/auth/facebook`;
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        userDetails,
        setUserDetails,
        isAuthorizing,
        setIsAuthorizing,
        isListOwner,
        setIsListOwner,
        facebookAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
