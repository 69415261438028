import { useState, useContext, ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ListsContext } from "../Contexts/ListsContext";
import { ListInterface } from "../Interfaces/ListInterface";
import { useTranslation } from "react-i18next";
import { CooperationContext } from "../Contexts/CooperationContext";

export default function ListNameAndDescSection() {
  const { t } = useTranslation();

  const { lists, updateListName, updateListDescription } =
    useContext(ListsContext);
  const { setActiveEdit } = useContext(CooperationContext);
  const { listId } = useParams();

  const [listName, setListName] = useState("");
  const [listDescription, setListDescription] = useState("");

  const changeListName = (e: ChangeEvent<HTMLInputElement>) => {
    setListName(e.target.value);
    updateListName(listId, e.target.value);
  };

  const changeListDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setListDescription(e.target.value);
    updateListDescription(listId, e.target.value);
  };

  // useEffect to fetch list data when component mounts or when listId or lists changes
  useEffect(() => {
    const list = lists.find((list: ListInterface) => list.id === listId);
    if (list) {
      setListName(list.name);
      setListDescription(list.description);
    }
  }, [listId, lists]);

  return (
    <section className="flex flex-col w-full lg:w-3/4">
      <input
        type="text"
        className="input input-ghost w-full font-bold text-3xl"
        name="name"
        placeholder={t("create_list.your_list_name") || ""}
        spellCheck="false"
        maxLength={255}
        value={listName}
        onChange={(e) => changeListName(e)}
        onFocus={() =>
          setActiveEdit({
            type: "list",
            id: listId,
            fieldName: "name",
          })
        }
        onBlur={() =>
          setActiveEdit({
            type: "",
            id: "",
            fieldName: "",
          })
        }
      />
      <input
        type="text"
        className="input input-ghost w-full max-h-8"
        name="description"
        placeholder={t("create_list.your_list_description") || ""}
        spellCheck="false"
        maxLength={2000}
        value={listDescription}
        onChange={(e) => changeListDescription(e)}
        onFocus={() =>
          setActiveEdit({
            type: "list",
            id: listId,
            fieldName: "description",
          })
        }
        onBlur={() =>
          setActiveEdit({
            type: "",
            id: "",
            fieldName: "",
          })
        }
      />
    </section>
  );
}
