import {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from "react";
import { ItemInterface } from "../Interfaces/ListInterface";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";
import { calculateWeightInGrams } from "../Utilities/calculateWeightInGrams";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { AlertContext } from "./AlertContext";
import { AuthContext } from "./AuthContext";
import { getCookie } from "../Utilities/getCookie";
import { useInitialList } from "../Utilities/initialList";

export const FavoriteItemsContext = createContext<any>(null);

export const FavoriteItemsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [favoriteItems, setFavoriteItems] = useState<ItemInterface[]>([]);
  const [favoriteItemsAreLoading, setFavoriteItemsAreLoading] = useState(true);
  const { initialItem } = useInitialList();

  const { displayAlert } = useContext(AlertContext);
  const { authenticated, userDetails } = useContext(AuthContext);

  const API_URL = process.env.REACT_APP_API_URL;

  const addItemToFavorite = (item: ItemInterface) => {
    // Use the abstracted function to display the alert
    displayAlert("alert.item_added_to_favorite", "success");

    // Deep copy the item
    const newItem: ItemInterface = JSON.parse(JSON.stringify(item));

    // Assign a new ID to the copied item
    newItem.id = uuidv4();

    setFavoriteItems((prevItems) => [...prevItems, newItem]);
  };

  const addFavoriteItem = () => {
    const newFavoriteItem = {
      ...initialItem,
      id: uuidv4(),
      ownerId: userDetails?.id,
      saved: true,
    };
    setFavoriteItems((prevItems) => [...prevItems, newFavoriteItem]);

    ReactPixel.trackCustom("AddFavoriteItem");

    ReactGA4.event("add_favorite_item", {
      name: "add_favorite_item",
      label: "Add Favorite Item",
    });

    // Send the event to the backend
    sendEventToBackend(
      "AddFavoriteItem",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  };

  const changeFavoriteItemInfo = (itemId: string, key: string, value: any) => {
    setFavoriteItems(
      favoriteItems.map((item) =>
        item.id === itemId ? { ...item, [key]: value } : item
      )
    );
  };

  const changeFavoriteItemWeight = (
    itemId: string,
    field: string,
    value: any
  ) => {
    setFavoriteItems(
      favoriteItems.map((item) => {
        if (item.id === itemId) {
          let unit = field === "weightUnit" ? value : item.weightUnit;
          let weight = field === "weight" ? Number(value) : Number(item.weight);

          if (isNaN(weight)) {
            weight = 0;
          }

          const weightInGrams = calculateWeightInGrams(weight, unit);
          return { ...item, weightInGrams, [field]: value };
        }
        return item;
      })
    );
  };

  const changeFavoriteItemBooleanField = (itemId: string, field: string) => {
    setFavoriteItems(
      favoriteItems.map((item) => {
        if (item.id === itemId) {
          // Prevent 'worn' and 'consumable' from both being true
          if (
            (field === "worn" && item.consumable) ||
            (field === "consumable" && item.worn)
          ) {
            return item;
          }
          // Copy item and invert field
          const updatedItem = { ...item };
          updatedItem[field] = !updatedItem[field];
          return updatedItem;
        }
        return item;
      })
    );
  };

  const deleteFavoriteItem = async (id: string) => {
    // Send DELETE request to server
    try {
      const response = await axios.delete(`${API_URL}/favorite-items/${id}`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        // Remove from local state
        setFavoriteItems((prevItems) => {
          return prevItems.filter((item) => item.id !== id);
        });
      }
    } catch (error) {
      console.error("Error deleting favorite item from the database:", error);
    }
  };

  useEffect(() => {
    const saveFavoriteItems = async () => {
      if (!authenticated) return;

      try {
        await axios.post(`${API_URL}/favorite-items`, favoriteItems, {
          withCredentials: true,
        });
      } catch (error) {
        console.error("Error saving favorite items:", error);
      }
    };

    saveFavoriteItems();
  }, [favoriteItems, API_URL, authenticated]);

  useEffect(() => {
    const fetchFavoriteItems = async () => {
      if (!authenticated) return;

      // Indicate loading start
      setFavoriteItemsAreLoading(true);
      try {
        const response = await axios.get(`${API_URL}/favorite-items`, {
          withCredentials: true,
        });
        setFavoriteItems(response.data);
      } catch (error) {
        console.error("Error fetching favorite items:", error);
      } finally {
        setFavoriteItemsAreLoading(false);
      }
    };

    fetchFavoriteItems();
  }, [authenticated, API_URL]);

  return (
    <FavoriteItemsContext.Provider
      value={{
        favoriteItems,
        addItemToFavorite,
        deleteFavoriteItem,
        favoriteItemsAreLoading,
        changeFavoriteItemInfo,
        changeFavoriteItemWeight,
        changeFavoriteItemBooleanField,
        addFavoriteItem,
      }}
    >
      {children}
    </FavoriteItemsContext.Provider>
  );
};
