import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import App from "./App";
import "./i18n";
import { ListsProvider } from "./Contexts/ListsContext";
import { AuthProvider } from "./Contexts/AuthContext";
import { FavoriteItemsProvider } from "./Contexts/FavoriteItemsContext";
import { AlertProvider } from "./Contexts/AlertContext";
import { ThemeProvider } from "./Contexts/ThemeContext";
import { CooperationProvider } from "./Contexts/CooperationContext";
import { GlobalItemsProvider } from "./Contexts/GlobalItemsContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <BrowserRouter>
          <AlertProvider>
            <ListsProvider>
              <GlobalItemsProvider>
                <FavoriteItemsProvider>
                  <ThemeProvider>
                    <CooperationProvider>
                      <App />
                    </CooperationProvider>
                  </ThemeProvider>
                </FavoriteItemsProvider>
              </GlobalItemsProvider>
            </ListsProvider>
          </AlertProvider>
        </BrowserRouter>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
);
