import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import polandFlag from "../Assets/flags/flag-of-Poland.png";
import usaFlag from "../Assets/flags/flag-of-United-States-of-America.png";
import czechFlag from "../Assets/flags/flag-of-Czech-Republic.png";
import germanyFlag from "../Assets/flags/flag-of-Germany.png";
import norwayFlag from "../Assets/flags/flag-of-Norway.png";
import russiaFlag from "../Assets/flags/flag-of-Russia.png";
import slovakiaFlag from "../Assets/flags/flag-of-Slovakia.png";
import spainFlag from "../Assets/flags/flag-of-Spain.png";
import ukraineFlag from "../Assets/flags/flag-of-Ukraine.png";
import swedenFlag from "../Assets/flags/flag-of-Sweden.png";
import chinaFlag from "../Assets/flags/flag-of-China.png";
import japanFlag from "../Assets/flags/flag-of-Japan.png";
import franceFlag from "../Assets/flags/flag-of-France.png";

interface LanguageOption {
  code: string;
  name: string;
  flag: string;
}

const languages: LanguageOption[] = [
  { code: "en", name: "English", flag: `${usaFlag}` },
  { code: "pl", name: "Polski", flag: `${polandFlag}` },
  { code: "sk", name: "Slovenský", flag: `${slovakiaFlag}` },
  { code: "cz", name: "Čeština", flag: `${czechFlag}` },
  { code: "de", name: "Deutsch", flag: `${germanyFlag}` },
  { code: "fr", name: "Français", flag: `${franceFlag}` },
  { code: "ua", name: "Українська", flag: `${ukraineFlag}` },
  { code: "ru", name: "Русский", flag: `${russiaFlag}` },
  { code: "es", name: "Español", flag: `${spainFlag}` },
  { code: "no", name: "Norsk", flag: `${norwayFlag}` },
  { code: "se", name: "Svenska", flag: `${swedenFlag}` },
  { code: "jp", name: "日本語", flag: `${japanFlag}` },
  { code: "cn", name: "中國人", flag: `${chinaFlag}` },
];

export default function LanguageSwitcher() {
  const detailsRef = useRef<HTMLDetailsElement | null>(null);

  const { i18n } = useTranslation();

  const changeLanguage = (e: React.MouseEvent, language: string) => {
    e.stopPropagation();
    i18n.changeLanguage(language);
    if (detailsRef.current) {
      detailsRef.current.removeAttribute("open");
    }
  };

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        detailsRef.current &&
        !detailsRef.current.contains(event.target as Node)
      ) {
        detailsRef.current.removeAttribute("open");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <details ref={detailsRef} className="dropdown">
      <summary className="m-1 w-auto flex cursor-pointer">
        <img
          className="w-7 border border-black"
          src={languages.find((lang) => lang.code === i18n.language)?.flag}
          alt={languages.find((lang) => lang.code === i18n.language)?.name}
        />
      </summary>
      <ul className="w-36 p-2 pl-4 border border-base-200 shadow dropdown-content z-[1] bg-base-100 rounded-box -translate-x-14 lg:-translate-x-9">
        {languages.map(({ code, name, flag }) => (
          <li
            className="flex items-center cursor-pointer"
            key={code}
            onClick={(e) => changeLanguage(e, code)}
          >
            <img
              className="w-7 h-fit mr-1 border border-black"
              src={flag}
              alt={name}
            />{" "}
            {name}
          </li>
        ))}
      </ul>
    </details>
  );
}
