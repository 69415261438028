import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReactGA4 from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { ListsContext } from "../Contexts/ListsContext";
import { AuthContext } from "../Contexts/AuthContext";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { getCookie } from "../Utilities/getCookie";

export default function NavItems({
  isMobile,
  checkboxRef,
}: {
  isMobile: boolean;
  checkboxRef: any;
}) {
  const { t } = useTranslation();

  const { addNewList } = useContext(ListsContext);
  const { setAuthenticated, userDetails } = useContext(AuthContext);

  const navigate = useNavigate(); // Get the useNavigate function

  const API_URL = process.env.REACT_APP_API_URL;

  const handleAddListClick = async () => {
    try {
      const newListId = await addNewList(); // Wait for the Promise to resolve to get the actual ID
      navigate(`/list/${newListId}`); // Navigate to the creator section with new list's ID

      if (isMobile && checkboxRef && checkboxRef.current)
        checkboxRef.current.checked = false;
    } catch (error) {
      console.error("Error adding new list and navigating:", error);
    }
  };

  const handleLogoutClick = () => {
    axios
      .get(`${API_URL}/logout`, { withCredentials: true })
      .then(() => {
        if (isMobile && checkboxRef && checkboxRef.current)
          checkboxRef.current.checked = false;
        setAuthenticated(false);
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleBuyCoffeClick = () => {
    ReactPixel.trackCustom("ClickBuyCoffe");

    ReactGA4.event("click_buy_coffe", {
      name: "click_buy_coffe",
      label: "Click Buy Coffe",
    });

    // Send to backend
    sendEventToBackend(
      "ClickBuyCoffe",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  };

  const handleClick = () => {
    if (isMobile && checkboxRef && checkboxRef.current)
      checkboxRef.current.checked = false;
  };

  return (
    <>
      <li>
        <button
          onClick={handleAddListClick}
          className={isMobile ? "" : "tooltip tooltip-right"}
          data-tip={isMobile ? "" : t("nav.create_list")}
        >
          <span className="material-symbols-outlined flex">add_circle</span>
          {isMobile ? t("nav.create_list") : ""}
        </button>
      </li>
      <li>
        <Link
          to="/lists"
          className={isMobile ? "" : "tooltip tooltip-right"}
          data-tip={isMobile ? "" : t("nav.gear_lists")}
          onClick={isMobile ? () => handleClick() : undefined}
        >
          <span className="material-symbols-outlined flex">list_alt</span>
          {isMobile ? t("nav.gear_lists") : ""}
        </Link>
      </li>
      <li>
        <Link
          to="/favorite"
          className={isMobile ? "" : "tooltip tooltip-right"}
          data-tip={isMobile ? "" : t("nav.favorite_items")}
          onClick={isMobile ? () => handleClick() : undefined}
        >
          <span className="material-symbols-outlined flex">star</span>
          {isMobile ? t("nav.favorite_items") : ""}
        </Link>
      </li>
      <li>
        <Link
          to="/global-items"
          className={isMobile ? "" : "tooltip tooltip-right"}
          data-tip={isMobile ? "" : t("nav.global_items")}
          onClick={isMobile ? () => handleClick() : undefined}
        >
          <span className="material-symbols-outlined flex">list</span>
          {isMobile ? t("nav.global_items") : ""}
        </Link>
      </li>
      <li>
        <Link
          to="/help"
          className={isMobile ? "" : "tooltip tooltip-right"}
          data-tip={isMobile ? "" : t("nav.how_it_works")}
          onClick={isMobile ? () => handleClick() : undefined}
        >
          <span className="material-symbols-outlined flex">help</span>
          {isMobile ? t("nav.how_it_works") : ""}
        </Link>
      </li>
      <div className="divider mt-auto"></div>
      <li>
        <a
          href="https://buycoffee.to/jankamon"
          target="_blank"
          rel="noreferrer"
          className={isMobile ? "" : "tooltip tooltip-right"}
          data-tip={isMobile ? "" : t("nav.buy_me_a_coffe")}
          onClick={() => handleBuyCoffeClick()}
        >
          <span className="material-symbols-outlined flex">coffee</span>
          {isMobile ? t("nav.buy_me_a_coffe") : ""}
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/groups/246571195015758"
          target="_blank"
          rel="noreferrer"
          className={isMobile ? "" : "tooltip tooltip-right"}
          data-tip={isMobile ? "" : t("nav.join_our_community")}
        >
          <span className="material-symbols-outlined flex">groups</span>
          {isMobile ? t("nav.join_our_community") : ""}
        </a>
      </li>
      <li>
        <Link
          to="/contact"
          className={isMobile ? "" : "tooltip tooltip-right"}
          data-tip={isMobile ? "" : t("nav.contact")}
          onClick={isMobile ? () => handleClick() : undefined}
        >
          <span className="material-symbols-outlined flex">chat</span>
          {isMobile ? t("nav.contact") : ""}
        </Link>
      </li>
      <li>
        <Link
          to="/user"
          className={isMobile ? "" : "tooltip tooltip-right"}
          data-tip={isMobile ? "" : t("nav.user_panel")}
          onClick={isMobile ? () => handleClick() : undefined}
        >
          <span className="material-symbols-outlined flex">account_circle</span>
          {isMobile ? t("nav.user_panel") : ""}
        </Link>
      </li>
      <li>
        <button
          className={isMobile ? "" : "tooltip tooltip-right"}
          data-tip={isMobile ? "" : t("nav.logout")}
          onClick={handleLogoutClick}
        >
          <span className="material-symbols-outlined flex">logout</span>
          {isMobile ? t("nav.logout") : ""}
        </button>
      </li>
    </>
  );
}
