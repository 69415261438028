import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { getDateString } from "../Utilities/getDateString";
import { CategoryInterface, ItemInterface } from "../Interfaces/ListInterface";

export const useInitialList = () => {
  const { t } = useTranslation();

  const initialItem: ItemInterface = useMemo(
    () => ({
      id: uuidv4(),
      type: "",
      name: "",
      brand: "",
      price: "",
      volume: "",
      weight: "",
      weightUnit: "g",
      weightInGrams: 0,
      link: "",
      quantity: 1,
      checked: false,
      worn: false,
      consumable: false,
      ownerId: "",
    }),
    []
  );

  const initialCategory: CategoryInterface = useMemo(
    () => ({
      id: uuidv4(),
      name: "",
      items: [initialItem],
    }),
    [initialItem]
  );

  const initialList = useMemo(
    () => ({
      id: uuidv4(),
      name: `${t("common.list_created")} ${getDateString()}`,
      description: "",
      lastUpdated: new Date(),
      settings: {
        showPrice: false,
        currency: "PLN",
        wornItems: true,
        consumableItems: true,
        checklist: false,
        summaryWeightUnit: "kg",
        public: false,
        cooperation: false,
      },
      categories: [initialCategory],
    }),
    [t, initialCategory]
  );

  return {
    initialItem,
    initialCategory,
    initialList,
  };
};
