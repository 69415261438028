import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CreateListSection from "./CreateListSection";
import PublicListPage from "./PublicListPage";
import Loading from "./Loading";
import ListIsPrivateError from "./ListIsPrivateError";
import ListNotFoundError from "./ListNotFoundError";
import { AuthContext } from "../Contexts/AuthContext";

export default function ListGate() {
  const API_URL = process.env.REACT_APP_API_URL;

  const { listId } = useParams();

  const { isListOwner, setIsListOwner } = useContext(AuthContext);

  const [canEdit, setCanEdit] = useState(false);
  const [isPublic, setIsPublic] = useState(null);
  const [cooperation, setCooperation] = useState(true);
  const [errorStatus, setErrorStatus] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_URL}/list-permissions/${listId}`, {
        withCredentials: true,
      })
      .then((response) => {
        setIsListOwner(response.data.isOwner);
        setIsPublic(response.data.isPublic);
        setCanEdit(response.data.canEdit);
        setCooperation(response.data.cooperation);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error(error.response.status);
          setErrorStatus(error.response.status);
        } else {
          console.error(error);
        }
      });
  }, [API_URL, listId, setIsListOwner]);

  // 1. Handle edge cases: errors
  if (errorStatus === 404) return <ListNotFoundError />;

  // 2. Handle loading state
  if (isListOwner === null || isPublic === null) return <Loading />;

  // 3. Handle conditions based on permissions and list properties

  // For the owner of the list (regardless of other settings)
  if (isListOwner) {
    return <CreateListSection />;
  }

  // If cooperation is enabled and the current user is an editor
  if (cooperation && canEdit) {
    return <CreateListSection />;
  }

  // For public lists
  if (isPublic) {
    return <PublicListPage />;
  }

  // For private lists that the current user doesn't own or can't edit
  return <ListIsPrivateError />;
}
