import { useEffect, useContext } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import ReactGA4 from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { hotjar } from "react-hotjar";
import axios from "axios";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { AuthContext } from "./Contexts/AuthContext";
import { AlertContext } from "./Contexts/AlertContext";
import SidebarNav from "./Components/SidebarNav";
import GearListsSection from "./Components/GearListsSection";
import HelpSection from "./Components/HelpSection";
import LandingPage from "./Components/LandingPage";
import ThemeAndLanguageSwitches from "./Components/ThemeAndLanguageSwitches";
import UserPanel from "./Components/UserPanel";
import ContactSection from "./Components/ContactSection";
import MobileTopNav from "./Components/MobileTopNav";
import Loading from "./Components/Loading";
import FavoriteItemsSection from "./Components/FavoriteItemsSection";
import AlertMessage from "./Components/AlertMessage";
import ListGate from "./Components/ListGate";
import PrivacyPolicyModal from "./Modals/PrivacyPolicyModal";
import TermsAndConditionsModal from "./Modals/TermsAndConditionsModal";
import PrivacyPolicyText from "./Components/PrivacyPolicyText";
import TermsAndConditionsText from "./Components/TermsAndConditionsText";
import { sendEventToBackend } from "./Utilities/sendEventToBackend";
import { getCookie } from "./Utilities/getCookie";
import GlobalItemsSection from "./Components/GlobalItemsSection";
import DownloadAppModal from "./Modals/DownloadAppModal";
import DownloadAppPopupLogic from "./Components/DownloadAppPopupLogic";

function App() {
  const {
    authenticated,
    setAuthenticated,
    setUserDetails,
    isAuthorizing,
    setIsAuthorizing,
    userDetails,
  } = useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);

  const { t } = useTranslation();

  const location = useLocation();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios
      .get(`${API_URL}/checkAuthentication`, {
        withCredentials: true, // Important for sending the cookie to the server
      })
      .then((response) => {
        setAuthenticated(response.data.authenticated);
        setUserDetails(response.data.user);
        setIsAuthorizing(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [API_URL, setAuthenticated, setIsAuthorizing, setUserDetails]);

  // Initialize GA4, FB Pixel and Hotjar
  useEffect(() => {
    if (process.env.REACT_APP_FB_PIXEL_MEASUREMENT_ID) {
      ReactPixel.init(
        `${process.env.REACT_APP_FB_PIXEL_MEASUREMENT_ID}`,
        undefined,
        {
          autoConfig: true,
          debug: false,
        }
      );
    }

    if (process.env.REACT_APP_GA4_MEASUREMENT_ID) {
      ReactGA4.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);
    }

    if (process.env.REACT_APP_HOTJAR_MEASUREMENT_ID) {
      hotjar.initialize(
        parseInt(process.env.REACT_APP_HOTJAR_MEASUREMENT_ID),
        6
      );
    }
  }, []);

  // Send GA4 and FB Pixel "pageview"
  useEffect(() => {
    ReactPixel.pageView();

    ReactGA4.send("pageview");

    sendEventToBackend(
      "PageView",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  }, [location, userDetails?.email]);

  return isAuthorizing ? (
    <Loading />
  ) : (
    <div className="flex flex-col lg:flex-row h-screen w-full">
      {authenticated ? (
        <>
          <SidebarNav />
        </>
      ) : null}
      <MobileTopNav />
      <Routes>
        {authenticated ? (
          // Routes for authenticated users
          <>
            <Route path="/list/:listId" element={<ListGate />} />
            <Route path="/lists" element={<GearListsSection />} />
            <Route path="/favorite" element={<FavoriteItemsSection />} />
            <Route path="/global-items" element={<GlobalItemsSection />} />
            <Route path="/help" element={<HelpSection />} />
            <Route path="/user" element={<UserPanel />} />
            <Route path="/contact" element={<ContactSection />} />
            <Route path="/privacy" element={<PrivacyPolicyText />} />
            <Route path="/terms" element={<TermsAndConditionsText />} />
            <Route path="*" element={<Navigate to="/lists" />} />
          </>
        ) : (
          // Route for unauthenticated users
          <>
            <Route path="/list/:listId" element={<ListGate />} />
            <Route path="/privacy" element={<PrivacyPolicyText />} />
            <Route path="/terms" element={<TermsAndConditionsText />} />
            <Route path="*" element={<LandingPage />} />
          </>
        )}
      </Routes>
      <ThemeAndLanguageSwitches />
      <PrivacyPolicyModal />
      <TermsAndConditionsModal />
      <DownloadAppModal />
      <DownloadAppPopupLogic />
      {showAlert ? <AlertMessage /> : ""}
      <CookieConsent
        location="none"
        containerClasses="cookie-consent"
        buttonClasses="cookie-consent-button"
        buttonText={t("common.ok_close")}
        cookieName="HikePack_cookieConsent"
        expires={150}
      >
        {t("cookies")}
        <button
          className="link"
          onClick={() => window.privacy_policy_modal.showModal()}
        >
          {t("common.privacy_policy_2")}
        </button>
        .
      </CookieConsent>
    </div>
  );
}

export default App;
