export const formatCurrency = (amount: number, currency: string) => {
  switch (currency) {
    case "USD":
      return `$ ${amount}`;
    case "EUR":
      return `€ ${amount}`;
    case "GBP":
      return `£ ${amount}`;
    case "PLN":
      return `${amount} zł`;
    default:
      return `${amount} ${currency}`;
  }
}
