import { useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListInterface, CategoryInterface } from "../Interfaces/ListInterface";
import { ListsContext } from "../Contexts/ListsContext";
import Item from "./Item";
import { convertWeight } from "../Utilities/convertWeight";
import {
  calculateCategoryTotalPrice,
  calculateCategoryTotalWeight,
  calculateCategoryTotalQuantity,
} from "../Utilities/calculateTotals";
import { formatCurrency } from "../Utilities/formatCurrency";
import { CooperationContext } from "../Contexts/CooperationContext";
import { GlobalItemsContext } from "../Contexts/GlobalItemsContext";

export default function Category({
  category,
}: {
  category: CategoryInterface;
}) {
  const { t } = useTranslation();

  const { listId } = useParams();

  const {
    lists,
    removeCategory,
    addNewItem,
    changeCategoryName,
    setCategoryId,
  } = useContext(ListsContext);
  const { setActiveEdit } = useContext(CooperationContext);
  const { fetchGlobalItems, fetchGlobalItemsCount } =
    useContext(GlobalItemsContext);

  const currentList = lists.find((list: ListInterface) => list.id === listId);

  const handleAddNewItem = () => {
    addNewItem(listId, category.id);
  };

  const handleShowFavoriteItemsModal = () => {
    setCategoryId(category.id);
    window.favorite_items_modal.showModal();
  };

  const handleShowGlobalItemsModal = () => {
    fetchGlobalItems();
    fetchGlobalItemsCount();
    setCategoryId(category.id);
    window.global_items_modal.showModal();
  };

  const handleDeleteCategoryClick = (
    listId: string | undefined,
    categoryId: string
  ) => {
    if (window.confirm("Czy na pewno chcesz usunąć tę kategorię?")) {
      removeCategory(listId, categoryId);
    }
  };

  return (
    <div className="mt-4 lg:mt-12">
      <input
        type="text"
        className="input w-full max-w-lg h-10 text-2xl mb-2"
        name="name"
        placeholder={t("create_list.category_name") || ""}
        spellCheck="false"
        maxLength={255}
        value={category.name}
        onChange={(e) =>
          changeCategoryName(listId, category.id, e.target.value)
        }
        onFocus={() =>
          setActiveEdit({
            type: "category",
            id: category.id,
            fieldName: "name",
          })
        }
        onBlur={() =>
          setActiveEdit({
            type: "",
            id: "",
            fieldName: "",
          })
        }
      />
      <div className="overflow-x-auto border rounded border-base-300">
        <table className="table">
          <thead>
            <tr>
              <td>{t("common.type")}</td>
              <td>{t("common.brand")}</td>
              <td>{t("common.name")}</td>
              {currentList.settings.checklist && <td></td>}
              {currentList.settings.wornItems && <td></td>}
              {currentList.settings.consumableItems && <td></td>}
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {currentList.settings.showPrice && <td>{t("common.price")}</td>}
              <td>{t("common.weight")}</td>
              <td>{t("common.quantity")}</td>
              <td>
                <button
                  onClick={() => handleDeleteCategoryClick(listId, category.id)}
                  className="material-symbols-outlined delete-button"
                >
                  delete
                </button>
              </td>
            </tr>
          </thead>
          <tbody>
            {/* rows */}
            {category.items.map((item) => {
              return (
                <Item key={item.id} item={item} categoryId={category.id} />
              );
            })}
          </tbody>
          <tfoot className="border-t border-base-200 border-solid">
            <tr>
              <td className="relative flex h-36 lg:h-[4.5rem] items-center">
                <div className="absolute flex flex-col lg:flex-row gap-2 items-center">
                  <button
                    onClick={handleAddNewItem}
                    className="btn flex-nowrap"
                  >
                    <span className="material-symbols-outlined">
                      add_circle
                    </span>
                    {t("create_list.add_item")}
                  </button>
                  <span>lub</span>
                  <button
                    onClick={handleShowFavoriteItemsModal}
                    className="btn flex-nowrap"
                  >
                    <span className="material-symbols-outlined">star</span>
                    {t("create_list.add_from_favorite")}
                  </button>
                  <button
                    onClick={handleShowGlobalItemsModal}
                    className="btn flex-nowrap"
                  >
                    <span className="material-symbols-outlined">list</span>
                    {t("create_list.add_from_global_items_list")}
                  </button>
                </div>
              </td>
              <td></td>
              <td></td>
              {currentList.settings.checklist && <td></td>}
              {currentList.settings.wornItems && <td></td>}
              {currentList.settings.consumableItems && <td></td>}
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {currentList.settings.showPrice && (
                <td>
                  {formatCurrency(
                    calculateCategoryTotalPrice(category),
                    currentList.settings.currency
                  )}
                </td>
              )}
              <td>{` ${
                calculateCategoryTotalWeight(category)
                  ? convertWeight(
                      calculateCategoryTotalWeight(category),
                      currentList.settings.summaryWeightUnit
                    )
                  : 0
              } 
            ${t(`weight_units.${currentList.settings.summaryWeightUnit}`)}
            `}</td>
              <td>{calculateCategoryTotalQuantity(category)}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
