import { useTranslation } from "react-i18next";
import logo from "../Assets/logo/logo.png";

export default function ListNotFoundError() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 items-center justify-center w-full">
      <a href="https://hikepack.app/" target="_blank" rel="noreferrer">
        <img src={logo} alt="HikePack logo" className="h-24 lg:h-24 mt-1" />
      </a>
      <div></div>
      <p>{t("error.list_doesnt_exists")}</p>
      <a href="https://hikepack.app/" className="btn btn-accent">
        {t("create_list.create_your_own_list")}
      </a>
    </div>
  );
}
