import { useRef, useEffect, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import Chart from "chart.js/auto";
import { ListInterface } from "../Interfaces/ListInterface";
import { convertWeight } from "../Utilities/convertWeight";
import {
  calculateCategoryTotalPrice,
  calculateCategoryTotalWeight,
} from "../Utilities/calculateTotals";
import { ThemeContext } from "../Contexts/ThemeContext";

export default function ChartDisplay({
  currentList,
}: {
  currentList: ListInterface;
}) {
  const { t } = useTranslation();

  const chartRef = useRef(null);
  const { isDark } = useContext(ThemeContext);

  //Show price
  const showPrice = currentList ? currentList.settings.showPrice : false;

  // Extract the currency setting from the current list
  const currency = currentList ? currentList.settings.currency : "";

  const categoriesData = useMemo(() => {
    return currentList ? currentList.categories : [];
  }, [currentList]);

  const categoryColors = useMemo(() => {
    return [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#DE4F26",
      "#65DD66",
      "#D471B4",
      "#FFAFA1",
      "#1E7368",
      "#B8D7FF",
      "#82E39D",
    ];
  }, []);

  useEffect(() => {
    let borderColor = isDark ? "black" : "white";

    let datasets = [
      {
        label: `${t("common.weight")}`,
        data: categoriesData.map((category: any) =>
          calculateCategoryTotalWeight(category)
        ), // send raw weight data
        backgroundColor: categoriesData.map(
          (_: any, index: any) => categoryColors[index % categoryColors.length]
        ),
        borderColor: borderColor,
        borderWidth: 2,
      },
    ];

    if (showPrice) {
      datasets.push({
        label: `${t("common.price")}`,
        data: categoriesData.map((category: any) =>
          calculateCategoryTotalPrice(category)
        ),
        backgroundColor: categoriesData.map(
          (_: any, index: any) => categoryColors[index % categoryColors.length]
        ),
        borderColor: borderColor,
        borderWidth: 2,
      });
    }

    const chartData = {
      labels: categoriesData.map((category: any) => category.name),
      datasets: datasets,
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context: any) {
              let label = context.label || "";

              if (label) {
                label += ": ";
                if (context.datasetIndex === 0) {
                  // For weight
                  label += convertWeight(
                    context.raw, // use raw weight for conversion
                    currentList.settings.summaryWeightUnit
                  );
                  label += ` ${t(
                    `weight_units.${currentList.settings.summaryWeightUnit}`
                  )}`;
                } else if (context.datasetIndex === 1) {
                  // For price
                  label += context.parsed;
                  label += " " + currency;
                }
              }

              return label;
            },
          },
        },
      },
    };

    if (chartRef.current) {
      const myChart = new Chart(chartRef.current, {
        type: "doughnut",
        data: chartData,
        options: chartOptions,
      });

      return () => {
        myChart.destroy();
      };
    }
  }, [
    categoriesData,
    showPrice,
    currency,
    currentList.settings.summaryWeightUnit,
    t,
    categoryColors,
    isDark,
  ]);

  return (
    <div className="lg:justify-self-end">
      <canvas ref={chartRef}></canvas>
    </div>
  );
}
