import { useTranslation } from "react-i18next";

export default function PrivacyPolicyText() {
  const { t } = useTranslation();

  return (
    <div className="overflow-auto">
      <h1 className="text-3xl">{t("privacy_policy.privacy_policy")}</h1>
      <p>{t("privacy_policy.privacy_policy_paragraph_1")}</p>
      <h2 className="text-2xl mt-4">
        {t("privacy_policy.general_informations")}
      </h2>
      <p>
        {t("privacy_policy.general_informations_paragraph_1")}
        <a className="link" href="https://hikepack.app/">
          https://hikepack.app/
        </a>
        {t("privacy_policy.general_informations_paragraph_2")}
      </p>
      <ul className="list-disc ml-6">
        <li> {t("privacy_policy.general_informations_paragraph_3")}</li>
        <li> {t("privacy_policy.general_informations_paragraph_4")}</li>
      </ul>
      <h2 className="text-2xl mt-4">
        {t("privacy_policy.data_protection_methods")}
      </h2>
      <p>{t("privacy_policy.data_protection_methods_paragraph_1")}</p>
      <h2 className="text-2xl mt-4"> {t("privacy_policy.hosting")}</h2>
      <p>{t("privacy_policy.hosting_paragraph_1")}</p>
      <h2 className="text-2xl mt-4">
        {t("privacy_policy.your_rights_and_how_data_is_used")}
      </h2>
      <p>{t("privacy_policy.your_rights_and_how_data_is_used_paragraph_1")}</p>
      <ul className="list-disc ml-6">
        <li>
          {t("privacy_policy.your_rights_and_how_data_is_used_paragraph_2")}
        </li>
      </ul>
      <p>{t("privacy_policy.your_rights_and_how_data_is_used_paragraph_3")}</p>
      <ul className="list-disc ml-6">
        <li>
          {t("privacy_policy.your_rights_and_how_data_is_used_paragraph_4")}
        </li>
        <li>
          {t("privacy_policy.your_rights_and_how_data_is_used_paragraph_5")}
        </li>
        <li>
          {t("privacy_policy.your_rights_and_how_data_is_used_paragraph_6")}
        </li>
        <li>
          {t("privacy_policy.your_rights_and_how_data_is_used_paragraph_7")}
        </li>
        <li>
          {t("privacy_policy.your_rights_and_how_data_is_used_paragraph_8")}
        </li>
      </ul>
      <p>{t("privacy_policy.your_rights_and_how_data_is_used_paragraph_9")}</p>
      <h2 className="text-2xl mt-4">
        {t("privacy_policy.information_in_forms")}
      </h2>
      <p>{t("privacy_policy.information_in_forms_paragraph_1")}</p>
      <h2 className="text-2xl mt-4">
        {t("privacy_policy.administrator_logs")}
      </h2>
      <p>{t("privacy_policy.administrator_logs_paragraph_1")}</p>
      <h2 className="text-2xl mt-4">
        {t("privacy_policy.marketing_techniques")}
      </h2>
      <p>{t("privacy_policy.marketing_techniques_paragraph_1")}</p>
      <h2 className="text-2xl mt-4">{t("privacy_policy.cookie_files")}</h2>
      <p>{t("privacy_policy.cookie_files_paragraph_1")}</p>
      <h2 className="text-2xl mt-4">{t("privacy_policy.managing_cookies")}</h2>
      <p>{t("privacy_policy.managing_cookies_paragraph_1")}</p>
      <ul className="list-disc ml-6">
        <li>
          <a
            href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history"
            rel="nofollow"
          >
            Edge
          </a>
        </li>
        <li>
          <a
            href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer"
            rel="nofollow"
          >
            Internet Explorer
          </a>
        </li>
        <li>
          <a
            href="http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647"
            rel="nofollow"
          >
            Chrome
          </a>
        </li>
        <li>
          <a href="http://support.apple.com/kb/PH5042" rel="nofollow">
            Safari
          </a>
        </li>
        <li>
          <a
            href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek"
            rel="nofollow"
          >
            Firefox
          </a>
        </li>
        <li>
          <a
            href="http://help.opera.com/Windows/12.10/pl/cookies.html"
            rel="nofollow"
          >
            Opera
          </a>
        </li>
      </ul>
      <p>Urządzenia mobilne:</p>
      <ul className="list-disc ml-6">
        <li>
          <a
            href="http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647"
            rel="nofollow"
          >
            Android
          </a>
        </li>
        <li>
          <a
            href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL"
            rel="nofollow"
          >
            Safari (iOS)
          </a>
        </li>
        <li>
          <a
            href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings"
            rel="nofollow"
          >
            Windows Phone
          </a>
        </li>
      </ul>
    </div>
  );
}
