import CSVReader from "react-csv-reader";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import ReactGA4 from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { ListsContext } from "../Contexts/ListsContext";
import {
  ListInterface,
  CategoryInterface,
  ItemInterface,
} from "../Interfaces/ListInterface";
import { calculateWeightInGrams } from "../Utilities/calculateWeightInGrams";
import { convertUnitToAbbreviation } from "../Utilities/convertUnitToAbbreviation";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { getCookie } from "../Utilities/getCookie";
import { AuthContext } from "../Contexts/AuthContext";

export default function ImportModal() {
  const { listId } = useParams();

  const { userDetails } = useContext(AuthContext);
  const { updateListCategories, lists } = useContext(ListsContext);

  const { t } = useTranslation();

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isOverwrite, setIsOverwrite] = useState(false);

  const handleForce = (data: any, fileInfo: any) => {
    try {
      // Get the existing categories from the context.
      const currentList = lists.find(
        (list: ListInterface) => list.id === listId
      );
      let categories: { [key: string]: CategoryInterface } = isOverwrite
        ? {}
        : { ...currentList.categories };

      // Ignore the header row
      data.slice(1).forEach((row: any) => {
        // Ignore empty rows
        if (!row[0]) return;

        const categoryName = row[1];
        if (!(categoryName in categories)) {
          categories[categoryName] = {
            id: uuidv4(),
            name: categoryName,
            items: [],
          };
        }

        const weight = isNaN(Number(row[4])) ? 0 : Number(row[4]);
        const price = isNaN(Number(row[7])) ? 0 : Number(row[7]);
        const quantity = isNaN(Number(row[3])) ? 1 : Number(row[3]);
        const weightUnit = convertUnitToAbbreviation(row[5]);
        const weightInGrams = isNaN(calculateWeightInGrams(row[4], weightUnit))
          ? 0
          : Number(calculateWeightInGrams(row[4], weightUnit));

        let item: ItemInterface = {
          id: uuidv4(),
          type: row[0],
          name: row[2],
          brand: "",
          price: price,
          volume: "",
          weight: weight,
          weightUnit: weightUnit,
          weightInGrams: weightInGrams,
          quantity: quantity,
          link: row[6],
          checked: false,
          worn: row[8] === "Worn",
          consumable: row[9] === "Consumable",
          ownerId: userDetails?.id,
        };

        // Add the new item to the category
        categories[categoryName].items.push(item);
      });

      const updatedCategories = Object.values(categories);

      // Updating the list categories
      updateListCategories(listId, updatedCategories);
      setIsOverwrite(false);

      setSuccess("import.file_imported_successfully");

      ReactPixel.trackCustom("ImportListFromLighterPack");

      ReactGA4.event("import_list_from_lighter_pack", {
        name: "import_list_from_lighter_pack",
        label: "Import List From Lighter Pack",
      });

      // Send to backend
      sendEventToBackend(
        "ImportListFromLighterPack",
        window.location.href,
        "website",
        userDetails?.email,
        getCookie("_fbp") || "",
        getCookie("_fbc") || ""
      );
    } catch (err) {
      // if an error occurs while processing the data, set the error message
      if (err instanceof Error) {
        setError("import.error_occured_while_processing_data");
        setErrorMessage(err.message);
      } else {
        setError("import.unknown_error");
      }
    }
  };

  return (
    <dialog id="import_modal" className="modal">
      <form method="dialog" className="modal-box">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
        <div className="flex flex-col justify-center items-center gap-4">
          <h2 className="text-xl">{t("import.import_from_lighterpack")}</h2>
          <p>{t("import.after_csv_selection")}</p>
          <CSVReader
            cssClass="csv-reader-input w-64"
            label={t("import.import_csv_file_from_lighterpack")}
            onFileLoaded={handleForce}
          />

          <div className="divider"></div>
          <p>{t("import.if_you_want_to_remove_old_categories")}</p>

          <label className="flex gap-2">
            <input
              className="checkbox checkbox-error"
              type="checkbox"
              checked={isOverwrite}
              onChange={() => setIsOverwrite(!isOverwrite)}
            />
            {t("import.overwrite_categories_and_items_optional")}
          </label>
          {success && (
            <div className="alert alert-success">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>{t(`${success}`)}</span>
            </div>
          )}
          {error && (
            <div className="alert alert-error">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>
                {t(`${error}`)} {errorMessage}
              </span>
            </div>
          )}
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
