import ThemeSwitcher from "./ThemeSwitcher";
import LanguageSwitcher from "./LanguageSwitcher";

export default function ThemeAndLanguageSwitches() {
  return (
    <div className="hidden lg:flex lg:w-24 lg:h-8 lg:absolute lg:top-0 lg:right-[3%] lg:bg-base-100 lg:border lg:border-base-200 lg:border-t-0 lg:rounded-b shadow lg:items-center lg:justify-center lg:gap-3">
      <LanguageSwitcher />
      <ThemeSwitcher />
    </div>
  );
}
