import { useEffect, useState } from "react";

const DownloadAppPopupLogic = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const lastShown = localStorage.getItem("appDownloadPopupLastShown");
    const oneWeek = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds

    if (!lastShown || Date.now() - parseInt(lastShown, 10) > oneWeek) {
      setShowPopup(true);
      localStorage.setItem("appDownloadPopupLastShown", Date.now().toString());
    } else {
      setShowPopup(false);
    }
  }, []);

  useEffect(() => {
    if (showPopup) window.download_app_modal.showModal();
  }, [showPopup]);

  return null;
};

export default DownloadAppPopupLogic;
