import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const API_URL = process.env.REACT_APP_API_URL;

  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);
    // Reset error and success messages
    setError("");
    setSuccess("");

    try {
      await axios.post(`${API_URL}/request-password-reset`, {
        email,
      });
      setSuccess(`${t("access.reset_link_has_been_sent")}`);
    } catch (error: any) {
      if (error.response.status === 404) {
        setError(`${t("access.wrong_email_address")}`);
      } else {
        setError(`${t("access.network_error")}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center">
      {success && (
        <div className="alert alert-success">
          <span>{success}</span>
        </div>
      )}
      {error && (
        <div className="alert alert-error">
          <span>{error}</span>
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 items-center"
      >
        <h2 className="text-lg font-bold">{t("access.password_reset")}</h2>
        <p>
          {t("access.enter_email_to_reset_password")}
          <br />
          {t("access.may_need_check_spam")}
        </p>
        <input
          type="email"
          placeholder={t("access.your_email") || ""}
          className="input input-bordered w-full"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="btn btn-accent w-full">
          {loading ? (
            <div className="flex gap-2 justify-center items-center">
              <span className="loading loading-spinner text-success"></span>
              <span>{t("common.loading")}</span>
            </div>
          ) : (
            <div>{t("access.request_password_reset")}</div>
          )}
        </button>
      </form>
    </div>
  );
}
