import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactGA4 from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { useTranslation } from "react-i18next";
import { ListInterface, ItemInterface } from "../Interfaces/ListInterface";
import { ListsContext } from "../Contexts/ListsContext";
import { FavoriteItemsContext } from "../Contexts/FavoriteItemsContext";
import { generateAmazonUrl } from "../Utilities/generateAmazonUrl";
import { CooperationContext } from "../Contexts/CooperationContext";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { getCookie } from "../Utilities/getCookie";
import { AuthContext } from "../Contexts/AuthContext";
import { GlobalItemsContext } from "../Contexts/GlobalItemsContext";

export default function Item({
  item,
  categoryId,
}: {
  item: ItemInterface;
  categoryId: string;
}) {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const { listId } = useParams();

  const { userDetails } = useContext(AuthContext);
  const {
    lists,
    removeItem,
    changeItemInfo,
    changeItemBooleanField,
    changeItemWeight,
    setCurrentItem,
    setCurrentCategoryId,
  } = useContext(ListsContext);
  const { addItemToFavorite } = useContext(FavoriteItemsContext);
  const { addItemToGlobalItems } = useContext(GlobalItemsContext);
  const { setActiveEdit } = useContext(CooperationContext);

  const currentList = lists.find((list: ListInterface) => list.id === listId);

  //Show or hide items price, worn and consumable
  const checklist = currentList ? currentList.settings.checklist : false;
  const showPrice = currentList ? currentList.settings.showPrice : false;
  const wornItems = currentList ? currentList.settings.wornItems : false;
  const consumableItems = currentList
    ? currentList.settings.consumableItems
    : false;

  //Is item worn or consumable
  const isItemChecked = item.checked || false;
  const isItemWorn = item.worn;
  const isItemConsumable = item.consumable;

  //checking input
  const isNumeric = (str: string) => {
    return /^\d*(\.\d+)?$/.test(str);
  };

  const handleRemoveItem = () => {
    removeItem(listId, categoryId, item.id);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    changeItemInfo(listId, categoryId, item.id, e.target.name, e.target.value);
  };

  const handleWeightChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    changeItemWeight(
      listId,
      categoryId,
      item.id,
      e.target.name,
      e.target.value
    );
  };

  const handleButtonClick = (field: string) => () => {
    changeItemBooleanField(listId, categoryId, item.id, field);
  };

  const handleAddLinkClick = () => {
    setCurrentItem(item);
    setCurrentCategoryId(categoryId);
    window.item_link_modal.showModal();
  };

  const handleAddItemToFavoriteClick = () => {
    addItemToFavorite(item);

    ReactPixel.trackCustom("AddItemToFavorite");

    ReactGA4.event("add_item_to_favorite", {
      name: "add_item_to_favorite",
      label: "Add Item To Favorite",
    });

    // Send to backend
    sendEventToBackend(
      "AddItemToFavorite",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  };

  const handleAddItemToGlobalItemsClick = () => {
    addItemToGlobalItems(item);
  };

  const handleBuyOnAmazonClick = () => {
    ReactPixel.trackCustom("ClickBuyOnAmazon");
    ReactPixel.trackCustom("ClickBuyOnAmazonEditingList");

    ReactGA4.event("click_buy_on_amazon", {
      name: "click_buy_on_amazon",
      label: "Click buy on Amazon",
      item_type: item.type,
      item_name: item.name,
      list_id: currentList.id,
    });

    ReactGA4.event("click_buy_on_amazon_editing_list", {
      name: "click_buy_on_amazon_editing_list",
      label: "Click buy on Amazon Editing List",
      item_type: item.type,
      item_name: item.name,
      list_id: currentList.id,
    });

    // Send to backend
    const customData = {
      item_type: item.type,
      item_name: item.name,
      list_id: currentList.id,
    };
    sendEventToBackend(
      "ClickBuyOnAmazon",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || "",
      customData
    );
    sendEventToBackend(
      "ClickBuyOnAmazonEditingList",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || "",
      customData
    );
  };

  useEffect(() => {
    // Listen for language changes
    const onLanguageChange = (lng: any) => {
      setCurrentLanguage(lng);
    };

    i18n.on("languageChanged", onLanguageChange);

    // Cleanup the listener on unmount
    return () => {
      i18n.off("languageChanged", onLanguageChange);
    };
  }, [i18n]);

  return (
    <tr className="hover">
      <td>
        <input
          name="type"
          className="input input-ghost min-w-[12rem] w-full max-w-xs"
          placeholder={t("create_list.item_type") || ""}
          spellCheck="false"
          maxLength={255}
          value={item.type}
          onChange={(e) => handleChange(e)}
          onFocus={() =>
            setActiveEdit({
              type: "item",
              id: item.id,
              fieldName: "type",
            })
          }
          onBlur={() =>
            setActiveEdit({
              type: "",
              id: "",
              fieldName: "",
            })
          }
        />
      </td>
      <td>
        <input
          name="brand"
          className="input input-ghost min-w-[12rem] w-full max-w-xs"
          placeholder={t("create_list.item_brand") || ""}
          spellCheck="false"
          maxLength={255}
          value={item.brand}
          onChange={(e) => handleChange(e)}
          onFocus={() =>
            setActiveEdit({
              type: "item",
              id: item.id,
              fieldName: "brand",
            })
          }
          onBlur={() =>
            setActiveEdit({
              type: "",
              id: "",
              fieldName: "",
            })
          }
        />
      </td>
      <td>
        <input
          type="text"
          name="name"
          className="input input-ghost min-w-[12rem] w-full max-w-xs"
          placeholder={t("create_list.item_name") || ""}
          spellCheck="false"
          maxLength={255}
          value={item.name}
          onChange={(e) => handleChange(e)}
          onFocus={() =>
            setActiveEdit({
              type: "item",
              id: item.id,
              fieldName: "name",
            })
          }
          onBlur={() =>
            setActiveEdit({
              type: "",
              id: "",
              fieldName: "",
            })
          }
        />
      </td>
      {checklist && (
        <td>
          <input
            type="checkbox"
            checked={isItemChecked}
            className="checkbox checkbox-success"
            onChange={handleButtonClick("checked")}
            onFocus={() =>
              setActiveEdit({
                type: "item",
                id: item.id,
                fieldName: "checked",
              })
            }
            onBlur={() =>
              setActiveEdit({
                type: "",
                id: "",
                fieldName: "",
              })
            }
          />
        </td>
      )}
      {wornItems && (
        <td>
          <button
            title={t("create_list.worn") || ""}
            className={`material-symbols-outlined item-buttons ${
              isItemWorn ? "active-item-button" : ""
            }`}
            onClick={handleButtonClick("worn")}
            onFocus={() =>
              setActiveEdit({
                type: "item",
                id: item.id,
                fieldName: "worn",
              })
            }
            onBlur={() =>
              setActiveEdit({
                type: "",
                id: "",
                fieldName: "",
              })
            }
          >
            laundry
          </button>
        </td>
      )}
      {consumableItems && (
        <td>
          <button
            title={t("create_list.consumable") || ""}
            className={`material-symbols-outlined item-buttons ${
              isItemConsumable ? "active-item-button" : ""
            }`}
            onClick={handleButtonClick("consumable")}
            onFocus={() =>
              setActiveEdit({
                type: "item",
                id: item.id,
                fieldName: "consumable",
              })
            }
            onBlur={() =>
              setActiveEdit({
                type: "",
                id: "",
                fieldName: "",
              })
            }
          >
            restaurant
          </button>
        </td>
      )}
      <td>
        <button
          title={t("create_list.add_to_link") || ""}
          className={`material-symbols-outlined item-buttons ${
            item.link ? "active-item-button" : ""
          }`}
          onClick={() => handleAddLinkClick()}
        >
          add_link
        </button>
      </td>
      <td>
        <a
          href={generateAmazonUrl(
            item.type,
            item.brand,
            item.name,
            currentLanguage
          )}
          target="_blank"
          rel="noreferrer"
        >
          <button
            title={t("create_list.buy_on_amazon") || ""}
            className="material-symbols-outlined item-buttons"
            onClick={() => handleBuyOnAmazonClick()}
          >
            shopping_cart
          </button>
        </a>
      </td>
      <td>
        <button
          title={t("create_list.add_to_favorite") || ""}
          className="material-symbols-outlined star-button"
          onClick={() => handleAddItemToFavoriteClick()}
        >
          star
        </button>
      </td>
      <td>
        <button
          title={t("create_list.add_to_global_items") || ""}
          className="material-symbols-outlined star-button"
          onClick={() => handleAddItemToGlobalItemsClick()}
        >
          playlist_add
        </button>
      </td>
      {showPrice && (
        <td>
          <input
            type="number"
            inputMode="decimal"
            name="price"
            className="input input-ghost min-w-[6rem] w-full max-w-xs"
            placeholder={t("common.price") || ""}
            value={item.price}
            onChange={(e) => {
              const value = e.target.value;
              if (isNumeric(value) && value.toString().length <= 8) {
                handleChange(e);
              }
            }}
            onFocus={() =>
              setActiveEdit({
                type: "item",
                id: item.id,
                fieldName: "price",
              })
            }
            onBlur={() =>
              setActiveEdit({
                type: "",
                id: "",
                fieldName: "",
              })
            }
          />
        </td>
      )}
      <td>
        <section className="flex">
          <input
            type="number"
            inputMode="decimal"
            name="weight"
            className="input input-ghost min-w-[5rem] w-full max-w-xs"
            placeholder={t("common.weight") || ""}
            value={item.weight}
            onChange={(e) => {
              const value = e.target.value;
              if (isNumeric(value) && value.toString().length <= 8) {
                handleWeightChange(e);
              }
            }}
            onFocus={() =>
              setActiveEdit({
                type: "item",
                id: item.id,
                fieldName: "weight",
              })
            }
            onBlur={() =>
              setActiveEdit({
                type: "",
                id: "",
                fieldName: "",
              })
            }
          />
          <select
            name="weightUnit"
            className="input input-ghost w-16 max-w-[4rem] p-0 cursor-pointer"
            value={item.weightUnit}
            onChange={(e) => handleWeightChange(e)}
            onFocus={() =>
              setActiveEdit({
                type: "item",
                id: item.id,
                fieldName: "weightUnit",
              })
            }
            onBlur={() =>
              setActiveEdit({
                type: "",
                id: "",
                fieldName: "",
              })
            }
          >
            <option value="g">{t("weight_units.g")}</option>
            <option value="kg">{t("weight_units.kg")}</option>
            <option value="lb">{t("weight_units.lb")}</option>
            <option value="oz">{t("weight_units.oz")}</option>
          </select>
        </section>
      </td>
      <td>
        <input
          type="number"
          inputMode="decimal"
          name="quantity"
          className="input input-ghost w-24 max-w-[6rem]"
          placeholder={t("common.quantity") || ""}
          value={item.quantity}
          onChange={(e) => {
            const value = e.target.value;
            if (isNumeric(value) && value.toString().length <= 8) {
              handleChange(e);
            }
          }}
          onFocus={() =>
            setActiveEdit({
              type: "item",
              id: item.id,
              fieldName: "quantity",
            })
          }
          onBlur={() =>
            setActiveEdit({
              type: "",
              id: "",
              fieldName: "",
            })
          }
        />
      </td>
      <td>
        <button
          onClick={handleRemoveItem}
          className="material-symbols-outlined delete-button"
        >
          delete
        </button>
      </td>
    </tr>
  );
}
