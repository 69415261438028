import { useTranslation } from "react-i18next";
import { useContext } from "react";
import logo from "../Assets/logo/logo.png";
import { AuthContext } from "../Contexts/AuthContext";
import { ListsContext } from "../Contexts/ListsContext";
import { ListInterface } from "../Interfaces/ListInterface";

export default function PublicListHeader({
  currentList,
}: {
  currentList: ListInterface | null;
}) {
  const { t } = useTranslation();

  const { authenticated } = useContext(AuthContext);
  const { copyList } = useContext(ListsContext);

  const handleCopyList = () => {
    if (authenticated) {
      copyList(currentList);
    } else {
      window.no_permissions_to_edit_list_modal.showModal();
    }
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center w-full gap-8">
      <a href="https://hikepack.app/" target="_blank" rel="noreferrer">
        <img src={logo} alt="HikePack logo" className="h-24 lg:h-24 mt-1" />
      </a>
      <div className="flex items-center gap-4">
        <a
          href="https://hikepack.app/register"
          target="_blank"
          rel="noreferrer"
          className="btn btn-accent"
        >
          {t("create_list.create_your_own_list")}
        </a>
        {t("common.or")}
        <button className="btn btn-accent" onClick={() => handleCopyList()}>
          {t("public_list.copy_this_list")}
        </button>
      </div>
    </div>
  );
}
