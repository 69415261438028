import logo from "../Assets/logo/favicon.png";
import NavItems from "./NavItems";

export default function SidebarNav() {

  return (
    // old bg color #292f4c
    <nav className="hidden lg:menu lg:bg-base-300 lg:items-center">
      <img src={logo} alt="HikePack Logo" className="w-12" />
      <div className="divider"></div>
      <ul className="flex flex-col justify-between flex-grow">
        <NavItems isMobile={false} checkboxRef={null} />
      </ul>
    </nav>
  );
}
