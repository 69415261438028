import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { GlobalItemsContext } from "../Contexts/GlobalItemsContext";
import { calculateWeightInGrams } from "../Utilities/calculateWeightInGrams";

export default function GlobalItemsSearch() {
  const API_URL = process.env.REACT_APP_API_URL;

  const { t } = useTranslation();

  const { setGlobalItems, setGlobalItemsAreLoading } =
    useContext(GlobalItemsContext);

  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("g");

  //checking input
  const isNumeric = (str: string) => {
    return /^\d*(\.\d+)?$/.test(str);
  };

  // useRef for debouncer
  const fetchItemsTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchItems = async () => {
      setGlobalItemsAreLoading(true);

      try {
        // Convert weight to grams only if weight is numeric
        const weightInGrams = isNumeric(weight)
          ? calculateWeightInGrams(Number(weight), weightUnit)
          : 0;

        const response = await axios.get(`${API_URL}/global-items/search`, {
          params: {
            type,
            brand,
            name,
            weightInGrams: weightInGrams > 0 ? weightInGrams : null,
          },
          withCredentials: true,
        });

        if (response.status === 200) {
          setGlobalItems(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch items:", error);
      } finally {
        setGlobalItemsAreLoading(false);
      }
    };

    // Clear any existing timeouts
    if (fetchItemsTimeoutRef.current) {
      clearTimeout(fetchItemsTimeoutRef.current);
    }

    // Set a new timeout
    fetchItemsTimeoutRef.current = setTimeout(() => {
      fetchItems();
    }, 350); // 350 milliseconds debounce time

    // Cleanup function to clear timeout when component updates/unmounts
    return () => {
      if (fetchItemsTimeoutRef.current) {
        clearTimeout(fetchItemsTimeoutRef.current);
      }
    };
  }, [
    type,
    brand,
    name,
    weight,
    weightUnit,
    API_URL,
    setGlobalItems,
    setGlobalItemsAreLoading,
  ]);

  return (
    <tr className="hover">
      <td>
        <input
          name="type"
          className="input input-bordered w-full max-w-xs"
          placeholder={t("create_list.item_type") || ""}
          maxLength={255}
          value={type}
          onChange={(e) => setType(e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          name="brand"
          className="input input-bordered w-full max-w-xs"
          placeholder={t("create_list.item_brand") || ""}
          maxLength={255}
          value={brand}
          onChange={(e) => setBrand(e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          name="name"
          className="input input-bordered w-full max-w-xs"
          placeholder={t("create_list.item_name") || ""}
          maxLength={255}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </td>
      <td>
        <section className="flex">
          <input
            type="number"
            inputMode="decimal"
            name="weight"
            className="input input-bordered min-w-[3.5rem] w-full max-w-xs"
            placeholder={t("common.weight_less_than") || ""}
            value={weight}
            onChange={(e) => {
              const value = e.target.value;
              if (isNumeric(value) && value.toString().length <= 8) {
                setWeight(e.target.value);
              }
            }}
          />
          <select
            name="weightUnit"
            className="input input-bordered w-16 max-w-[4rem] p-0 cursor-pointer"
            value={weightUnit}
            onChange={(e) => setWeightUnit(e.target.value)}
          >
            <option value="g">{t("weight_units.g")}</option>
            <option value="kg">{t("weight_units.kg")}</option>
            <option value="lb">{t("weight_units.lb")}</option>
            <option value="oz">{t("weight_units.oz")}</option>
          </select>
        </section>
      </td>
    </tr>
  );
}
