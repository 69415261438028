import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactGA4 from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { ItemInterface, ListInterface } from "../Interfaces/ListInterface";
import Loading from "./Loading";
import Chart from "./Chart";
import Summary from "./Summary";
import { convertWeight } from "../Utilities/convertWeight";
import { calculateWeightInGrams } from "../Utilities/calculateWeightInGrams";
import {
  calculateCategoryTotalPrice,
  calculateCategoryTotalWeight,
  calculateCategoryTotalQuantity,
} from "../Utilities/calculateTotals";
import { generateAmazonUrl } from "../Utilities/generateAmazonUrl";
import { AuthContext } from "../Contexts/AuthContext";
import { FavoriteItemsContext } from "../Contexts/FavoriteItemsContext";
import { formatCurrency } from "../Utilities/formatCurrency";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { getCookie } from "../Utilities/getCookie";

export default function PublicListBody({
  currentList,
  setCurrentList,
  ownerUsername,
}: {
  currentList: ListInterface | null;
  setCurrentList: any;
  ownerUsername: string | null;
}) {
  const { t, i18n } = useTranslation();
  const [currentLanguage] = useState(i18n.language);

  const { authenticated, userDetails } = useContext(AuthContext);
  const { addItemToFavorite } = useContext(FavoriteItemsContext);

  //Show or hide items price, worn and consumable
  const showPrice = currentList ? currentList.settings.showPrice : false;
  const wornItems = currentList ? currentList.settings.wornItems : false;
  const consumableItems = currentList
    ? currentList.settings.consumableItems
    : false;

  const handleBuyOnAmazonClick = (item: any, currentList: any) => {
    ReactPixel.trackCustom("ClickBuyOnAmazon");
    ReactPixel.trackCustom("ClickBuyOnAmazonInPublicList");

    ReactGA4.event("click_buy_on_amazon", {
      name: "click_buy_on_amazon",
      label: "Click buy on Amazon",
      item_type: item.type,
      item_name: item.name,
      list_id: currentList.id,
    });

    ReactGA4.event("click_buy_on_amazon_in_public_list", {
      name: "click_buy_on_amazon_in_public_list",
      label: "Click buy on Amazon in public list",
      item_type: item.type,
      item_name: item.name,
      list_id: currentList.id,
    });

    // Send to backend
    const customData = {
      item_type: item.type,
      item_name: item.name,
      list_id: currentList.id,
    };

    sendEventToBackend(
      "ClickBuyOnAmazon",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || "",
      customData
    );
    sendEventToBackend(
      "ClickBuyOnAmazonInPublicList",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || "",
      customData
    );
  };

  const handleChangeItemWeightUnit = (
    categoryId: string,
    itemId: string,
    event: any
  ) => {
    if (currentList === null) return;

    const newCurrentList = {
      ...currentList,
      categories: currentList.categories.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            items: category.items.map((item) => {
              if (item.id === itemId) {
                const unit = event.target.value;
                const weightInGrams = calculateWeightInGrams(
                  Number(item.weight),
                  unit
                );
                return {
                  ...item,
                  weightUnit: unit,
                  weightInGrams,
                };
              }
              return item;
            }),
          };
        }
        return category;
      }),
    };

    setCurrentList(newCurrentList);
  };

  const handleAddItemToFavoriteClick = (item: ItemInterface) => {
    if (authenticated) {
      addItemToFavorite(item);

      ReactPixel.trackCustom("AddItemToFavoriteFromPublicList");

      ReactGA4.event("add_item_to_favorite_from_public_list", {
        name: "add_item_to_favorite_from_public_list",
        label: "Add Item To Favorite From Public List",
      });

      // Send to backend
      sendEventToBackend(
        "AddItemToFavoriteFromPublicList",
        window.location.href,
        "website",
        userDetails?.email,
        getCookie("_fbp") || "",
        getCookie("_fbc") || ""
      );
    } else {
      window.no_permissions_to_edit_list_modal.showModal();
    }
  };

  return currentList === null ? (
    <Loading />
  ) : (
    <div className="flex flex-col items-center gap-4 w-full">
      <section className="flex flex-col lg:flex-row border-b border-base-200 w-full">
        <div className="w-full lg:w-3/4">
          <h1 className="text-3xl font-bold">{currentList.name}</h1>
          <p>{currentList.description}</p>
        </div>
        <div className="w-full lg:w-1/4">
          {ownerUsername ? <div>Author: {ownerUsername}</div> : ""}
        </div>
      </section>
      <section className="flex flex-col w-full gap-8 justify-center lg:flex-row lg:items-center">
        <Chart currentList={currentList} />
        <Summary
          currentList={currentList}
          setCurrentList={setCurrentList}
          forGuest={true}
        />
      </section>
      <section className="w-full">
        {currentList.categories.map((category) => {
          return (
            <div key={category.id} className="mt-4 lg:mt-12">
              <h2 className="text-2xl ml-4 mb-2">{category.name}</h2>
              <div className="overflow-x-auto border rounded border-base-300">
                <table className="table">
                  <thead>
                    <tr>
                      <td>{t("common.type")}</td>
                      <td>{t("common.brand")}</td>
                      <td>{t("common.name")}</td>
                      {currentList.settings.wornItems && <td></td>}
                      {currentList.settings.consumableItems && <td></td>}
                      <td></td>
                      <td></td>
                      <td></td>
                      {currentList.settings.showPrice && (
                        <td>{t("common.price")}</td>
                      )}
                      <td>{t("common.weight")}</td>
                      <td>{t("common.quantity")}</td>
                    </tr>
                  </thead>
                  <tbody className="text-base">
                    {/* rows */}
                    {category.items.map((item) => {
                      return (
                        <tr key={item.id} className="hover">
                          <td>
                            <a
                              href={generateAmazonUrl(
                                item.type,
                                item.brand,
                                item.name,
                                currentLanguage
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.type}
                            </a>
                          </td>
                          <td>
                            <a
                              href={generateAmazonUrl(
                                item.type,
                                item.brand,
                                item.name,
                                currentLanguage
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.brand}
                            </a>
                          </td>
                          <td>
                            <a
                              href={generateAmazonUrl(
                                item.type,
                                item.brand,
                                item.name,
                                currentLanguage
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.name}
                            </a>
                          </td>
                          {wornItems && (
                            <td>
                              {item.worn && (
                                <span
                                  title={t("create_list.worn") || ""}
                                  className={
                                    "material-symbols-outlined item-buttons active-item-button"
                                  }
                                >
                                  laundry
                                </span>
                              )}
                            </td>
                          )}
                          {consumableItems && (
                            <td>
                              {item.consumable && (
                                <span
                                  title={t("create_list.consumable") || ""}
                                  className={
                                    "material-symbols-outlined item-buttons active-item-button"
                                  }
                                >
                                  restaurant
                                </span>
                              )}
                            </td>
                          )}
                          <td>
                            {/* {item.link && (
                              <button
                                title={t("create_list.item_link") || ""}
                                className={
                                  "material-symbols-outlined item-buttons"
                                }
                              >
                                link
                              </button>
                            )} */}
                          </td>
                          <td>
                            <a
                              href={generateAmazonUrl(
                                item.type,
                                item.brand,
                                item.name,
                                currentLanguage
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <button
                                title={t("create_list.buy_on_amazon") || ""}
                                className="material-symbols-outlined"
                                onClick={() =>
                                  handleBuyOnAmazonClick(item, currentList)
                                }
                              >
                                shopping_cart
                              </button>
                            </a>
                          </td>
                          <td>
                            <button
                              title={t("create_list.add_to_favorite") || ""}
                              className="material-symbols-outlined star-button"
                              onClick={() => handleAddItemToFavoriteClick(item)}
                            >
                              star
                            </button>
                          </td>
                          {showPrice && (
                            <td>
                              {formatCurrency(
                                Number(item.price),
                                currentList.settings.currency
                              )}
                            </td>
                          )}
                          <td>
                            <section>
                              <span>{item.weight}</span>{" "}
                              <select
                                name="weightUnit"
                                className="input input-ghost w-16 max-w-[2.5rem] max-h-6 p-0 cursor-pointer"
                                value={item.weightUnit}
                                onChange={(event) =>
                                  handleChangeItemWeightUnit(
                                    category.id,
                                    item.id,
                                    event
                                  )
                                }
                              >
                                <option value="g">{t("weight_units.g")}</option>
                                <option value="kg">
                                  {t("weight_units.kg")}
                                </option>
                                <option value="lb">
                                  {t("weight_units.lb")}
                                </option>
                                <option value="oz">
                                  {t("weight_units.oz")}
                                </option>
                              </select>
                            </section>
                          </td>
                          <td>
                            <span>{item.quantity}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot className="border-t border-base-200 border-solid">
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      {currentList.settings.wornItems && <td></td>}
                      {currentList.settings.consumableItems && <td></td>}
                      <td></td>
                      <td></td>
                      <td></td>
                      {currentList.settings.showPrice && (
                        <td>
                          {formatCurrency(
                            calculateCategoryTotalPrice(category),
                            currentList.settings.currency
                          )}
                        </td>
                      )}
                      <td>{` ${
                        calculateCategoryTotalWeight(category)
                          ? convertWeight(
                              calculateCategoryTotalWeight(category),
                              currentList.settings.summaryWeightUnit
                            )
                          : 0
                      } 
                ${t(`weight_units.${currentList.settings.summaryWeightUnit}`)}
                `}</td>
                      <td>{calculateCategoryTotalQuantity(category)}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
}
