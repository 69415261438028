import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA4 from "react-ga4";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import { AuthContext } from "../Contexts/AuthContext";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { getCookie } from "../Utilities/getCookie";

export default function Login() {
  const { t } = useTranslation();

  const { setAuthenticated, setUserDetails, facebookAuth } =
    useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = (event: any) => {
    event.preventDefault();

    axios
      .post(
        `${API_URL}/login`,
        {
          email: email,
          password: password,
        },
        {
          withCredentials: true, // IMPORTANT
        }
      )
      .then((response) => {
        setAuthenticated(true);
        setUserDetails(response.data.user);

        // Tracking events
        ReactPixel.track("SignIn");

        ReactGA4.event("signin", {
          name: "signin",
          label: "SignIn",
        });

        // Send to backend
        sendEventToBackend(
          "SignIn",
          window.location.href,
          "website",
          email,
          getCookie("_fbp") || "",
          getCookie("_fbc") || ""
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`${t("access.incorrect_data")}`);
        } else {
          setError(`${t("access.unknown_error")}`);
          setErrorCode(error.response.status);
        }
      });
  };

  return (
    <div className="flex flex-col gap-4">
      {error && (
        <div className="alert alert-error">
          <span>
            {error} {errorCode}
          </span>
        </div>
      )}
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <input
          type="email"
          id="email"
          name="email"
          placeholder={t("access.your_email") || ""}
          className="input input-bordered w-full"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          id="password"
          name="password"
          placeholder={t("access.your_password") || ""}
          className="input input-bordered w-full"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Link to="/forgot-password" className="link text-sm text-left">
          {t("access.forgot_password")}
        </Link>
        <Link to="/register" className="link text-sm text-left">
          {t("access.dont_have_account")}
        </Link>
        <button className="btn btn-accent" type="submit">
          {t("access.login")}
        </button>
        {/* <div className="divider">{t("common.or")}</div>
        <button className="btn btn-info" type="button" onClick={facebookAuth}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2em"
            viewBox="0 0 512 512"
          >
            <path
              fill="#FFFFFF"
              d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
            />
          </svg>
          {t("access.login_with_facebook")}
        </button> */}
      </form>
    </div>
  );
}
