import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ItemInterface } from "../Interfaces/ListInterface";
import { convertWeight } from "../Utilities/convertWeight";
import { ListsContext } from "../Contexts/ListsContext";
import { GlobalItemsContext } from "../Contexts/GlobalItemsContext";
import { AuthContext } from "../Contexts/AuthContext";

export default function GlobalItem({
  item,
  inModal,
}: {
  item: ItemInterface;
  inModal: boolean;
}) {
  const { t } = useTranslation();
  const { listId } = useParams();

  const { userDetails } = useContext(AuthContext);

  const { addItemFromGlobalItems, categoryId } = useContext(ListsContext);
  const {
    deleteGlobalItem,
    saveGlobalItem,
    changeGlobalItemInfo,
    changeGlobalItemWeight,
  } = useContext(GlobalItemsContext);

  const [convertedWeight, setConvertedWeight] = useState<string>(
    convertWeight(Number(item.weightInGrams), item.weightUnit)
  );

  useEffect(() => {
    setConvertedWeight(convertWeight(item.weightInGrams, item.weightUnit));
  }, [item.weightInGrams, item.weightUnit]);

  const handleWeightUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newUnit = e.target.value;
    const newConvertedWeight = convertWeight(item.weightInGrams, newUnit);
    setConvertedWeight(newConvertedWeight);
    // Assuming you want to update item's weightUnit as well
    item.weightUnit = newUnit;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    changeGlobalItemInfo(item.id, e.target.name, e.target.value);
  };

  const handleWeightChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    changeGlobalItemWeight(item.id, e.target.name, e.target.value);
  };

  const handleRemoveGlobalItem = () => {
    if (window.confirm(`${t("delete_confirmation.global_item")}`)) {
      deleteGlobalItem(item.id);
    }
  };

  //checking input
  const isNumeric = (str: string) => {
    return /^\d*(\.\d+)?$/.test(str);
  };

  return !inModal && item.ownerId === userDetails.id ? (
    <tr
      className={`hover ${inModal ? "cursor-pointer" : ""}`}
      onClick={
        inModal
          ? () => addItemFromGlobalItems(listId, categoryId, item)
          : undefined
      }
    >
      <td>
        <input
          name="type"
          className="input input-ghost w-full max-w-xs"
          placeholder={t("create_list.item_type") || ""}
          maxLength={255}
          value={item.type}
          onChange={(e) => handleChange(e)}
        />
      </td>
      <td>
        <input
          type="text"
          name="brand"
          className="input input-ghost w-full max-w-xs"
          placeholder={t("create_list.item_brand") || ""}
          maxLength={255}
          value={item.brand}
          onChange={(e) => handleChange(e)}
        />
      </td>
      <td>
        <input
          type="text"
          name="name"
          className="input input-ghost w-full max-w-xs"
          placeholder={t("create_list.item_name") || ""}
          maxLength={255}
          value={item.name}
          onChange={(e) => handleChange(e)}
        />
      </td>
      <td>
        <section>
          <input
            type="number"
            inputMode="decimal"
            name="weight"
            className="input input-ghost min-w-[3.5rem] w-full max-w-xs"
            placeholder={t("common.weight") || ""}
            value={item.weight}
            onChange={(e) => {
              const value = e.target.value;
              if (isNumeric(value) && value.toString().length <= 8) {
                handleWeightChange(e);
              }
            }}
          />
          <select
            name="weightUnit"
            className="input input-ghost w-16 max-w-[4rem] p-0 cursor-pointer"
            value={item.weightUnit}
            onChange={(e) => handleWeightChange(e)}
          >
            <option value="g">{t("weight_units.g")}</option>
            <option value="kg">{t("weight_units.kg")}</option>
            <option value="lb">{t("weight_units.lb")}</option>
            <option value="oz">{t("weight_units.oz")}</option>
          </select>
        </section>
      </td>
      <td className="w-40">
        <button onClick={() => saveGlobalItem(item)} className="btn">
          {t("common.save_changes")}
        </button>
      </td>
      <td>
        <button
          onClick={() => handleRemoveGlobalItem()}
          className="material-symbols-outlined delete-button"
        >
          delete
        </button>
      </td>
    </tr>
  ) : (
    <tr
      className={`hover ${inModal ? "cursor-pointer" : ""}`}
      onClick={
        inModal
          ? () => addItemFromGlobalItems(listId, categoryId, item)
          : undefined
      }
    >
      <td>
        <span>{item.type}</span>
      </td>
      <td>
        <span>{item.brand}</span>
      </td>
      <td>
        <span>{item.name}</span>
      </td>
      <td>
        <section>
          <span>{convertedWeight}</span>{" "}
          <select
            name="weightUnit"
            className="input input-ghost w-16 max-w-[4rem] p-0 cursor-pointer"
            value={item.weightUnit}
            onChange={(e) => handleWeightUnitChange(e)}
            onClick={(e) => e.stopPropagation()}
          >
            <option value="g">{t("weight_units.g")}</option>
            <option value="kg">{t("weight_units.kg")}</option>
            <option value="lb">{t("weight_units.lb")}</option>
            <option value="oz">{t("weight_units.oz")}</option>
          </select>
        </section>
      </td>
    </tr>
  );
}
