import SettingsModal from "../Modals/SettingsModal";
import ShareModal from "../Modals/ShareModal";
import ImportModal from "../Modals/ImportModal";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

export default function ListSettingsSection() {
  const { listId } = useParams();

  const { t } = useTranslation();

  return (
    <section className="flex justify-center items-center w-full lg:w-1/4 gap-6">
      {/* Share container and button */}
      <button className="flex" onClick={() => window.share_modal.showModal()}>
        <span className="material-symbols-outlined">link</span>
        <span className="ml-1">{t("common.share")}</span>
      </button>

      <ShareModal listId={listId} />

      {/* Import container and button */}
      <button className="flex" onClick={() => window.import_modal.showModal()}>
        <span className="material-symbols-outlined">add</span>
        <span className="ml-1">{t("common.import")}</span>
      </button>

      <ImportModal />

      {/* Settings container and button */}
      <button
        className="flex"
        onClick={() => window.settings_modal.showModal()}
      >
        <span className="material-symbols-outlined">settings</span>
        <span className="ml-1">{t("common.settings")}</span>
      </button>

      <SettingsModal />
    </section>
  );
}
