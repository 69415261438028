import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import PublicListBody from "./PublicListBody";
import PublicListHeader from "./PublicListHeader";
import { ListInterface } from "../Interfaces/ListInterface";
import Loading from "./Loading";

export default function PublicListPage() {
  const API_URL = process.env.REACT_APP_API_URL;

  const { t } = useTranslation();

  const { listId } = useParams();

  const [currentList, setCurrentList] = useState<ListInterface | null>(null);
  const [ownerUsername, setOwnerUsername] = useState<string | null>(null);

  useEffect(() => {
    async function fetchList() {
      try {
        const response = await axios.get(`${API_URL}/lists/${listId}`);
        setCurrentList(response.data.data); // Updated to fetch the list data

        const ownerResponse = await axios.get(
          `${API_URL}/username/${response.data.userId}` // Using userId from the response
        );
        setOwnerUsername(ownerResponse.data.username); // Updated to fetch the username
      } catch (error) {
        console.error("Failed to fetch the list:", error);
      }
    }

    fetchList();
  }, [listId, API_URL]);

  if (!currentList) return <Loading />;

  return (
    <div className="flex flex-col items-center w-full p-2 lg:p-8 lg:pt-2 overflow-auto">
      <Helmet>
        <title>HikePack - {currentList ? currentList.name : ""}</title>
        <meta
          property="og:title"
          content={currentList ? currentList.name : "HikePack"}
        />
        <meta
          property="og:description"
          content={currentList ? currentList.description : "Gear List"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="flex flex-col w-full gap-4 lg:w-3/4">
        <PublicListHeader currentList={currentList} />
        <PublicListBody
          currentList={currentList}
          setCurrentList={setCurrentList}
          ownerUsername={ownerUsername}
        />
      </div>
      <dialog id="no_permissions_to_edit_list_modal" className="modal">
        <form method="dialog" className="modal-box">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
          <div className="flex flex-col items-center gap-4">
            <p className="text-center">
              {t("public_list.you_must_be_loggedin_to_copy_list_or_add_item_to_favorite")}
            </p>
            <div className="flex items-center gap-2">
              <a
                href="https://hikepack.app/login"
                target="_blank"
                rel="noreferrer"
                className="btn btn-accent"
              >
                {t("access.login")}
              </a>
              {t("common.or")}

              <a
                href="https://hikepack.app/register"
                target="_blank"
                rel="noreferrer"
                className="btn btn-accent"
              >
                {t("access.register")}
              </a>
            </div>
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  );
}
