import { useContext, useRef } from "react";
import ThemeSwitcher from "./ThemeSwitcher";

import LanguageSwitcher from "./LanguageSwitcher";
import NavItems from "./NavItems";
import { AuthContext } from "../Contexts/AuthContext";

export default function MobileTopNav() {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { authenticated } = useContext(AuthContext);

  return (
    <div className="flex items-center justify-between bg-base-200 w-full lg:hidden">
      <ul className="menu menu-horizontal">
        <div className="drawer z-50">
          <input
            ref={checkboxRef}
            id="my-drawer"
            type="checkbox"
            className="drawer-toggle"
          />
          {authenticated ? (
            <label
              htmlFor="my-drawer"
              className="material-symbols-outlined text-3xl ml-2"
            >
              menu
            </label>
          ) : (
            <a href="https://hikepack.app/login">
              <button className="material-symbols-outlined text-2xl ml-1">
                login
              </button>
            </a>
          )}
          <div className="drawer-side">
            <label htmlFor="my-drawer" className="drawer-overlay"></label>
            <ul className="menu p-4 w-80 h-full bg-base-200 text-base-content">
              <NavItems isMobile={true} checkboxRef={checkboxRef} />
            </ul>
          </div>
        </div>
      </ul>
      <span className="flex items-center mr-5 gap-4 h-12">
        <LanguageSwitcher />
        <ThemeSwitcher />
      </span>
    </div>
  );
}
