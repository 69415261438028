import {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
  useRef,
} from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  ListInterface,
  CategoryInterface,
  ItemInterface,
} from "../Interfaces/ListInterface";
import { calculateWeightInGrams } from "../Utilities/calculateWeightInGrams";
import { useInitialList } from "../Utilities/initialList";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { AlertContext } from "./AlertContext";
import { AuthContext } from "./AuthContext";
import { getCookie } from "../Utilities/getCookie";
import { calculateListTotalWeight } from "../Utilities/calculateTotals";

export const ListsContext = createContext<any>(null);

export const ListsProvider = ({ children }: { children: ReactNode }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const { initialItem, initialCategory, initialList } = useInitialList();

  const [categoryId, setCategoryId] = useState(null);

  const { displayAlert } = useContext(AlertContext);
  const { authenticated, userDetails } = useContext(AuthContext);

  //Lists
  const [lists, setLists] = useState<ListInterface[]>([]);
  const [listsAreLoading, setListsAreLoading] = useState(true);
  const [isSavingLists, setIsSavingLists] = useState(false);

  const updateListName = (id: string, name: string) => {
    setLists(lists.map((list) => (list.id === id ? { ...list, name } : list)));
  };

  const updateListDescription = (id: string, description: string) => {
    setLists(
      lists.map((list) => (list.id === id ? { ...list, description } : list))
    );
  };

  const updateListSettings = (id: string, key: string, value: any) => {
    setLists(
      lists.map((list) =>
        list.id === id
          ? { ...list, settings: { ...list.settings, [key]: value } }
          : list
      )
    );
  };

  const updateSummaryWeightUnit = (id: string, summaryWeightUnit: string) => {
    setLists(
      lists.map((list) =>
        list.id === id
          ? { ...list, settings: { ...list.settings, summaryWeightUnit } }
          : list
      )
    );
  };

  const addNewList = async () => {
    const newList: ListInterface = {
      ...initialList,
      id: uuidv4(),
      ownerId: userDetails?.id,
    };

    setLists((prevLists) => [newList, ...prevLists]);

    // Now, save the new list to the database
    try {
      await axios.post(`${API_URL}/list/${newList.id}`, newList, {
        withCredentials: true,
      });

      ReactPixel.trackCustom("AddList");

      ReactGA4.event("add_list", {
        name: "add_list",
        label: "Add List",
      });

      // Send the event to the backend
      sendEventToBackend(
        "AddList",
        window.location.href,
        "website",
        userDetails?.email,
        getCookie("_fbp") || "",
        getCookie("_fbc") || ""
      );
    } catch (error) {
      console.error("An error occurred while saving the list:", error);
    }

    return newList.id; // return new list's id to use for routing
  };

  const deleteList = async (id: string) => {
    try {
      // Send a DELETE request to the server
      const response = await axios.delete(`${API_URL}/lists/${id}`, {
        withCredentials: true,
      });

      // Check if the deletion was successful
      if (response.status === 200) {
        // If successful, update the local state
        setLists(lists.filter((list) => list.id !== id));
      }
    } catch (error: any) {
      console.error("Error deleting list from the database:", error);

      // Check if error is 404 and display a specific alert
      if (error.response && error.response.status === 404) {
        displayAlert(
          "alert.list_doesnt_exists_or_you_dont_have_permissions",
          "error"
        );
      }
    }
  };

  const copyList = (listToCopy: ListInterface) => {
    displayAlert("alert.list_copied", "success");

    // Deep copy the list
    const copiedList: ListInterface = {
      ...JSON.parse(JSON.stringify(listToCopy)),
      id: uuidv4(),
      ownerId: userDetails.id,
      settings: { ...listToCopy.settings, public: false },
      categories: listToCopy.categories.map((category) => ({
        ...category,
        id: uuidv4(),
        items: category.items.map((item) => ({
          ...item,
          id: uuidv4(),
          image: "",
          ownerId: userDetails.id,
          checked: false,
        })),
      })),
    };
    setLists((prevLists) => [copiedList, ...prevLists]);

    ReactPixel.trackCustom("CopyList");
    ReactGA4.event("copy_list", {
      name: "copy_list",
      label: "Copy List",
    });

    sendEventToBackend(
      "CopyList",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  };

  //Categories
  const [currentCategoryId, setCurrentCategoryId] = useState("");

  const addNewCategory = (listId: string) => {
    const newCategory: CategoryInterface = {
      ...initialCategory,
      id: uuidv4(),
    };

    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: [...list.categories, newCategory],
          };
        }

        return list;
      })
    );

    ReactPixel.trackCustom("AddCategory");

    ReactGA4.event("add_category", {
      name: "add_category",
      label: "Add Category",
    });

    sendEventToBackend(
      "AddCategory",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  };

  const removeCategory = (listId: string, categoryId: string) => {
    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: list.categories.filter(
              (category) => category.id !== categoryId
            ),
          };
        }
        return list;
      })
    );
  };

  const changeCategoryName = (
    listId: string,
    categoryId: string,
    newName: string
  ) => {
    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: list.categories.map((category) =>
              category.id === categoryId
                ? { ...category, name: newName }
                : category
            ),
          };
        }
        return list;
      })
    );
  };

  //Updates categories after import
  const updateListCategories = (
    listId: string,
    categories: CategoryInterface[]
  ) => {
    setLists(
      lists.map((list) =>
        list.id === listId
          ? {
              ...list,
              categories,
            }
          : list
      )
    );
  };

  //Items
  const [currentItem, setCurrentItem] = useState({});

  const addNewItem = (listId: string, categoryId: string) => {
    const newItem: ItemInterface = {
      ...initialItem,
      id: uuidv4(),
      ownerId: userDetails?.id,
      saved: true,
    };

    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: list.categories.map((category) =>
              category.id === categoryId
                ? { ...category, items: [...category.items, newItem] }
                : category
            ),
          };
        }
        return list;
      })
    );

    ReactPixel.trackCustom("AddItem");

    ReactGA4.event("add_item", {
      name: "add_item",
      label: "Add Item",
    });

    sendEventToBackend(
      "AddItem",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  };

  const addItemFromFavorite = (
    listId: string,
    categoryId: string,
    favoriteItem: ItemInterface
  ) => {
    const newItem: ItemInterface = {
      ...favoriteItem,
      id: uuidv4(),
      ownerId: userDetails?.id,
      saved: true,
    };

    displayAlert("alert.item_added_to_list", "success");

    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: list.categories.map((category) =>
              category.id === categoryId
                ? { ...category, items: [...category.items, newItem] }
                : category
            ),
          };
        }
        return list;
      })
    );

    ReactPixel.trackCustom("AddItemFromFavorite");

    ReactGA4.event("add_item_from_favorite", {
      name: "add_item_from_favorite",
      label: "Add Item From Favorite",
    });

    sendEventToBackend(
      "AddItemFromFavorite",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  };

  const addItemFromGlobalItems = (
    listId: string,
    categoryId: string,
    globalItem: ItemInterface
  ) => {
    const { ownerId, ...restOfGlobalItem } = globalItem;

    const newItem: ItemInterface = {
      ...restOfGlobalItem,
      id: uuidv4(),
      ownerId: userDetails?.id,
      image: "",
      saved: true,
    };

    displayAlert("alert.item_added_to_list", "success");

    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: list.categories.map((category) =>
              category.id === categoryId
                ? { ...category, items: [...category.items, newItem] }
                : category
            ),
          };
        }
        return list;
      })
    );

    ReactPixel.trackCustom("AddItemFromGlobalItems");

    ReactGA4.event("add_item_from_global_items", {
      name: "add_item_from_global_items",
      label: "Add Item From Global Items",
    });

    sendEventToBackend(
      "AddItemFromGlobalItems",
      window.location.href,
      "website",
      userDetails?.email,
      getCookie("_fbp") || "",
      getCookie("_fbc") || ""
    );
  };

  const removeItem = (listId: string, categoryId: string, itemId: string) => {
    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: list.categories.map((category) =>
              category.id === categoryId
                ? {
                    ...category,
                    items: category.items.filter((item) => item.id !== itemId),
                  }
                : category
            ),
          };
        }
        return list;
      })
    );
  };

  const changeItemInfo = (
    listId: string,
    categoryId: string,
    itemId: string,
    key: string,
    value: any
  ) => {
    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: list.categories.map((category) => {
              if (category.id === categoryId) {
                return {
                  ...category,
                  items: category.items.map((item) =>
                    item.id === itemId ? { ...item, [key]: value } : item
                  ),
                };
              }
              return category;
            }),
          };
        }
        return list;
      })
    );
  };

  const changeItemBooleanField = (
    listId: string,
    categoryId: string,
    itemId: string,
    field: string
  ) => {
    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: list.categories.map((category) => {
              if (category.id === categoryId) {
                return {
                  ...category,
                  items: category.items.map((item) => {
                    if (item.id === itemId) {
                      // Prevent 'worn' and 'consumable' from both being true
                      if (
                        (field === "worn" && item.consumable) ||
                        (field === "consumable" && item.worn)
                      ) {
                        return item;
                      }
                      // copy item and invert field
                      const updatedItem = { ...item };
                      updatedItem[field] = !updatedItem[field];
                      return updatedItem;
                    }
                    return item;
                  }),
                };
              }
              return category;
            }),
          };
        }
        return list;
      })
    );
  };

  const changeItemWeight = (
    listId: string,
    categoryId: string,
    itemId: string,
    field: string,
    value: any
  ) => {
    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: list.categories.map((category) => {
              if (category.id === categoryId) {
                return {
                  ...category,
                  items: category.items.map((item) => {
                    if (item.id === itemId) {
                      let unit =
                        field === "weightUnit" ? value : item.weightUnit;
                      let weight =
                        field === "weight"
                          ? Number(value)
                          : Number(item.weight);

                      if (isNaN(weight)) {
                        weight = 0;
                      }

                      const weightInGrams = calculateWeightInGrams(
                        weight,
                        unit
                      );
                      return { ...item, weightInGrams, [field]: value };
                    }
                    return item;
                  }),
                };
              }
              return category;
            }),
          };
        }
        return list;
      })
    );
  };

  function uncheckAll(listId: string) {
    setLists(
      lists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            categories: list.categories.map((category) => ({
              ...category,
              items: category.items.map((item) => ({
                ...item,
                checked: false,
              })),
            })),
          };
        } else {
          return list;
        }
      })
    );
  }

  // create a ref for storing the timeout ID
  const saveListsTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [originalLists, setOriginalLists] = useState<ListInterface[]>([]);

  useEffect(() => {
    let isMounted = true;

    const saveLists = async () => {
      if (!authenticated) return;
      setIsSavingLists(true);

      if (originalLists.length === 0) {
        setOriginalLists(JSON.parse(JSON.stringify(lists)));
      }

      const extractRelevantData = (list: ListInterface) => {
        if (!list) return {}; // or a suitable default

        const { lastUpdated, ...rest } = list; // Using object destructuring to exclude lastUpdated
        return rest;
      };

      const listsAreEqual = (listA: ListInterface, listB: ListInterface) => {
        return (
          JSON.stringify(extractRelevantData(listA)) ===
          JSON.stringify(extractRelevantData(listB))
        );
      };

      const changedLists = lists
        .filter((list, index) => {
          return !listsAreEqual(list, originalLists[index]);
        })
        .map((list) => {
          return {
            ...list,
            totalWeightInGrams: calculateListTotalWeight(list),
          };
        });

      if (changedLists.length === 0) return; // No changes detected

      try {
        await axios.post(`${API_URL}/lists`, changedLists, {
          withCredentials: true,
        });

        if (isMounted) {
          setOriginalLists(JSON.parse(JSON.stringify(lists)));
        }
      } catch (error) {
        console.error("Error saving lists:", error);
      } finally {
        setIsSavingLists(false);
      }
    };

    // clear any existing timeouts
    if (saveListsTimeoutRef.current) {
      clearTimeout(saveListsTimeoutRef.current);
    }

    // set a new timeout
    saveListsTimeoutRef.current = setTimeout(() => {
      saveLists();
    }, 350); // 350 milliseconds debounce time

    // cleanup function to clear timeout when component unmounts
    return () => {
      if (saveListsTimeoutRef.current) {
        clearTimeout(saveListsTimeoutRef.current);
      }
      isMounted = false;
    };
  }, [lists, originalLists, API_URL, authenticated]);

  useEffect(() => {
    let isMounted = true; // Declare isMounted at the start of the useEffect

    const fetchLists = async () => {
      if (!authenticated) return;

      setListsAreLoading(true);
      try {
        const response = await axios.get(`${API_URL}/lists`, {
          withCredentials: true,
        });
        if (response.status === 200 && isMounted) {
          // Check isMounted before updating state
          setLists(response.data);
        }
      } catch (error) {
        console.error("Error fetching lists:", error);
      } finally {
        if (isMounted) {
          // Check isMounted before updating state
          setListsAreLoading(false);
        }
      }
    };

    fetchLists();

    return () => {
      isMounted = false; // Set isMounted to false in the cleanup function
    };
  }, [API_URL, authenticated]);

  return (
    <ListsContext.Provider
      value={{
        lists,
        listsAreLoading,
        setLists,
        updateListName,
        updateListDescription,
        addNewList,
        deleteList,
        copyList,
        updateListSettings,
        updateSummaryWeightUnit,
        addNewCategory,
        removeCategory,
        changeCategoryName,
        addNewItem,
        removeItem,
        changeItemInfo,
        changeItemBooleanField,
        changeItemWeight,
        updateListCategories,
        uncheckAll,
        addItemFromFavorite,
        categoryId,
        setCategoryId,
        currentItem,
        setCurrentItem,
        currentCategoryId,
        setCurrentCategoryId,
        isSavingLists,
        addItemFromGlobalItems,
      }}
    >
      {children}
    </ListsContext.Provider>
  );
};
