import { ListInterface, CategoryInterface } from "../Interfaces/ListInterface";

export const calculateListTotalWeight = (list: ListInterface): number => {
  let totalWeight = 0;
  list.categories.forEach((category) => {
    category.items.forEach((item) => {
      totalWeight += Number(item.weightInGrams) * Number(item.quantity);
    });
  });
  return parseFloat(totalWeight.toFixed(2));
};

export const calculateListTotalPrice = (list: ListInterface): number => {
  let totalPrice = 0;
  list.categories.forEach((category) => {
    category.items.forEach((item) => {
      totalPrice += Number(item.price) * Number(item.quantity);
    });
  });
  return parseFloat(totalPrice.toFixed(2));
};

export const calculateListTotalQuantity = (list: ListInterface): number => {
  let totalQuantity = 0;
  list.categories.forEach((category) => {
    category.items.forEach((item) => {
      totalQuantity += Number(item.quantity);
    });
  });
  return parseFloat(totalQuantity.toFixed(2));
};

export const calculateTotalWornWeight = (list: ListInterface): number => {
  let totalWeight = 0;
  list.categories.forEach((category) => {
    category.items.forEach((item) => {
      if (item.worn) {
        totalWeight += Number(item.weightInGrams);
      }
    });
  });
  return parseFloat(totalWeight.toFixed(2));
};

export const calculateTotalWornPrice = (list: ListInterface): number => {
  let totalPrice = 0;
  list.categories.forEach((category) => {
    category.items.forEach((item) => {
      if (item.worn && typeof item.price === "number") {
        totalPrice += Number(item.price);
      }
    });
  });
  return parseFloat(totalPrice.toFixed(2));
};

export const calculateTotalConsumableWeight = (list: ListInterface): number => {
  let totalWeight = 0;
  list.categories.forEach((category) => {
    category.items.forEach((item) => {
      if (item.consumable) {
        totalWeight += Number(item.weightInGrams) * Number(item.quantity);
      }
    });
  });
  return parseFloat(totalWeight.toFixed(2));
};

export const calculateTotalConsumablePrice = (list: ListInterface): number => {
  let totalPrice = 0;
  list.categories.forEach((category) => {
    category.items.forEach((item) => {
      if (item.consumable && typeof item.price === "number") {
        totalPrice += Number(item.price) * Number(item.quantity);
      }
    });
  });
  return parseFloat(totalPrice.toFixed(2));
};

export const calculateCategoryTotalWeight = (
  category: CategoryInterface
): number => {
  let totalWeight = 0;
  category.items.forEach((item) => {
    totalWeight += Number(item.weightInGrams) * Number(item.quantity);
  });
  return parseFloat(totalWeight.toFixed(2));
};

export const calculateCategoryTotalPrice = (
  category: CategoryInterface
): number => {
  let totalPrice = 0;
  category.items.forEach((item) => {
    totalPrice += Number(item.price) * Number(item.quantity);
  });
  return parseFloat(totalPrice.toFixed(2));
};

export const calculateCategoryTotalQuantity = (
  category: CategoryInterface
): number => {
  let totalQuantity = 0;
  category.items.forEach((item) => {
    totalQuantity += Number(item.quantity);
  });
  return parseFloat(totalQuantity.toFixed(2));
};
