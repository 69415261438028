import { useContext } from "react";
import { useParams } from "react-router-dom";
import { ListInterface, CategoryInterface } from "../Interfaces/ListInterface";
import { useTranslation } from "react-i18next";
import Category from "./Category";
import { ListsContext } from "../Contexts/ListsContext";
import ItemLinkModal from "../Modals/ItemLinkModal";

export default function ListBody() {
  const { t } = useTranslation();
  const { listId } = useParams();
  const { lists, addNewCategory } = useContext(ListsContext);

  const currentList = lists.find((list: ListInterface) => list.id === listId);

  const categories = currentList ? currentList.categories : [];

  return (
    <div>
      {categories.map((category: CategoryInterface) => {
        return <Category key={category.id} category={category} />;
      })}

      <button
        className="btn self-start mt-4"
        onClick={() => addNewCategory(listId)}
      >
        <span className="material-symbols-outlined">add_circle</span>
        {t("create_list.add_category")}
      </button>
      <ItemLinkModal />
    </div>
  );
}
