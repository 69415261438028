export const calculateWeightInGrams = (weight: number, unit: string) => {
  switch (unit) {
    case "kg":
      return weight * 1000;
    case "lb":
      return weight * 453.592;
    case "oz":
      return weight * 28.3495;
    default:
      return weight; // Assuming 'g' as default
  }
};
