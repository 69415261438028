import { useTranslation } from "react-i18next";
import googlePlayIcon from "../Assets/images/300px-google-play-min.png";
import appStoreIcon from "../Assets/images/300px-app-store-min.png";

export default function DownloadAppModal() {
  const { t } = useTranslation();

  const openAppStore = () => {
    window.open(
      "https://apps.apple.com/pl/app/hikepack/id6504342831",
      "_blank"
    );
  };

  const openGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.janekks.hikepack",
      "_blank"
    );
  };
  return (
    <dialog id="download_app_modal" className="modal">
      <form method="dialog" className="modal-box w-11/12 max-w-3xl p-12">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
        <div className="flex flex-col justify-center items-center gap-4">
          <h2 className="text-2xl text-center">
            {t("downloadAppPopup.for_better_expirience_download")}{" "}
            <span className="font-bold">HikePack</span>
            {t("downloadAppPopup.app")}
          </h2>
          <p className="text-center -mt-4">
            {t("downloadAppPopup.might_be_better")}{" "}
          </p>
          <div className="flex flex-row gap-4 mt-4">
            <div
              className="flex flex-col items-center"
              onClick={openAppStore}
              style={{ cursor: "pointer" }}
            >
              <span className="text-xl text-center">
                {t("downloadAppPopup.download_from_appstore")}
              </span>
              <img
                src={appStoreIcon}
                alt={t("downloadAppPopup.download_from_appstore") || ""}
                style={{ width: "150px" }}
              />
            </div>
            <div
              className="flex flex-col items-center"
              onClick={openGooglePlay}
              style={{ cursor: "pointer" }}
            >
              <span className="text-xl text-center">
                {t("downloadAppPopup.download_from_googleplay")}
              </span>
              <img
                src={googlePlayIcon}
                alt={t("downloadAppPopup.download_from_googleplay") || ""}
                style={{ width: "150px" }}
              />
            </div>
          </div>
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
