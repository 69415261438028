import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ListsContext } from "../Contexts/ListsContext";
import { convertWeight } from "../Utilities/convertWeight";
import { useTranslation } from "react-i18next";
import {
  calculateListTotalPrice,
  calculateListTotalWeight,
  calculateListTotalQuantity,
} from "../Utilities/calculateTotals";
import Loading from "./Loading";
import ShareModal from "../Modals/ShareModal";
import { ListInterface } from "../Interfaces/ListInterface";

export default function GearListsSection() {
  const { t } = useTranslation();

  const { lists, listsAreLoading, addNewList, deleteList } =
    useContext(ListsContext);

  const navigate = useNavigate();

  const [listId, setListId] = useState();

  const handleAddListClick = async () => {
    try {
      const newListId = await addNewList(); // Wait for the Promise to resolve to get the actual ID
      navigate(`/list/${newListId}`); // Navigate to the creator section with new list's ID
    } catch (error) {
      console.error("Error adding new list and navigating:", error);
    }
  };

  const handleDeleteListClick = (event: any, id: string) => {
    event.stopPropagation();

    if (window.confirm(`${t("delete_confirmation.list")}`)) {
      deleteList(id);
    }
  };

  const handleShareListClick = (event: any, currentListId: any) => {
    event.stopPropagation();
    setListId(currentListId);
    window.share_modal.showModal();
  };

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (!listsAreLoading) {
      setIsInitialLoad(false);
    }
  }, [listsAreLoading]);

  return listsAreLoading && isInitialLoad ? (
    <Loading />
  ) : (
    <div className="flex flex-col w-full gap-4 p-2 lg:p-8 overflow-auto">
      <h2 className="text-2xl font-bold self-center">
        {t("your_lists.header")}
      </h2>
      <div className="border border-base-300 rounded w-full overflow-auto">
        <table className="table text-base">
          {/* head */}
          <thead>
            <tr>
              <th>{t("common.name")}</th>
              <th>{t("common.description")}</th>
              <th></th>
              <th></th>
              <th>{t("common.price")}</th>
              <th>{t("common.weight")}</th>
              <th>{t("common.quantity")}</th>
              <th>{t("common.delete")}</th>
            </tr>
          </thead>
          <tbody>
            {/* rows */}
            {lists.length === 0 ? (
              <tr>
                <td colSpan={6}>
                  Nie masz jeszcze żadnych list, kliknij przycisk poniżej i
                  utwórz pierwszą.
                </td>
              </tr>
            ) : (
              lists.map((list: ListInterface) => (
                <tr
                  className="cursor-pointer hover"
                  key={list.id}
                  onClick={() => navigate(`/list/${list.id}`)}
                >
                  <td>{list.name}</td>
                  <td>{list.description}</td>
                  <td>
                    <button
                      className="material-symbols-outlined item-buttons"
                      onClick={(e) => handleShareListClick(e, list.id)}
                    >
                      link
                    </button>
                  </td>
                  <td>
                    {list.settings.cooperation && (
                      <span className="material-symbols-outlined">group</span>
                    )}
                  </td>
                  <td>{`${calculateListTotalPrice(list)} ${
                    list.settings.currency
                  }`}</td>
                  <td>{`${convertWeight(
                    calculateListTotalWeight(list),
                    list.settings.summaryWeightUnit
                  )} ${list.settings.summaryWeightUnit}`}</td>
                  <td>{calculateListTotalQuantity(list)}</td>
                  <td>
                    <button
                      onClick={(event) => handleDeleteListClick(event, list.id)}
                      className="material-symbols-outlined delete-button"
                    >
                      delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <button onClick={handleAddListClick} className="btn self-start">
        <span className="material-symbols-outlined">add_circle</span>
        {t("your_lists.add_list")}
      </button>
      <ShareModal listId={listId} />
    </div>
  );
}
