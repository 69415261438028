import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { AlertContext } from "../Contexts/AlertContext";

export default function CooperationSettings({ listId }: { listId: string }) {
  const { t } = useTranslation();

  const { displayAlert } = useContext(AlertContext);

  const [editorEmail, setEditorEmail] = useState("");
  const [editorList, setEditorList] = useState<string[]>([]); // To store the list of editors
  const API_URL = process.env.REACT_APP_API_URL;

  const handleAddEditor = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/add-editor`,
        {
          listId,
          editorEmail,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        displayAlert("alert.editor_added_successfully", "success");
        setEditorList((prevList) => [...prevList, editorEmail]); // Add the new email to the list
        setEditorEmail("");
      } else {
        displayAlert("alert.failed_to_add_editor_try_again", "error");
      }
    } catch (error) {
      console.error("Error adding editor:", error);
      displayAlert("alert.failed_to_add_editor_try_again", "error");
    }
  };

  const handleRemoveEditor = async (emailToRemove: string) => {
    try {
      const response = await axios.delete(`${API_URL}/remove-editor`, {
        data: { listId, editorEmail: emailToRemove },
        withCredentials: true,
      });

      if (response.status === 200) {
        displayAlert("alert.editor_removed_successfully", "success");
        setEditorList((prevList) =>
          prevList.filter((email) => email !== emailToRemove)
        );
      } else {
        displayAlert("alert.failed_to_add_editor_try_again", "error");
      }
    } catch (error) {
      console.error("Error removing editor:", error);
      displayAlert("alert.failed_to_remove_editor_try_again", "error");
    }
  };

  useEffect(() => {
    // Fetch the list of editors when the component mounts
    const fetchEditors = async () => {
      try {
        const response = await axios.get(`${API_URL}/list-editors/${listId}`, {
          withCredentials: true,
        });
        if (response.status === 200 && response.data) {
          setEditorList(response.data.editors);
        }
      } catch (error) {
        console.error("Error fetching editors:", error);
      }
    };

    fetchEditors();
  }, [listId, API_URL]);

  return (
    <div className="flex flex-col gap-4 mt-2">
      <p className="text-xs">{t("cooperation.cooperation_is_beta")}</p>
      {editorList && (
        <div>
          <h3>{t("cooperation.list_of_editors")}</h3>
          <ul className="flex flex-col gap-1 w-72">
            {editorList.map((email) => (
              <li
                key={email}
                title={t("cooperation.remove_editor") || ""}
                className="flex justify-between items-center h-10 pl-2 pr-1 border-0 rounded bg-base-200 hover:bg-base-300 cursor-pointer"
                onClick={() => handleRemoveEditor(email)}
              >
                {email}
                <button
                  type="button"
                  className="material-symbols-outlined text-red-600"
                >
                  remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="flex gap-1 items-center w-72">
        <input
          className="input input-bordered w-full"
          value={editorEmail}
          onChange={(e) => setEditorEmail(e.target.value)}
          placeholder={t("cooperation.enter_editors_email") || ""}
          spellCheck="false"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleAddEditor();
            }
          }}
        />
        <button
          type="button"
          title={t("cooperation.add_editor") || ""}
          className="material-symbols-outlined text-green-600"
          onClick={handleAddEditor}
        >
          add
        </button>
      </div>
    </div>
  );
}
