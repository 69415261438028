import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ListsContext } from "../Contexts/ListsContext";
import { useParams } from "react-router-dom";
import { CooperationContext } from "../Contexts/CooperationContext";

export default function ItemLinkModal() {
  const { t } = useTranslation();
  const { listId } = useParams();

  const { currentItem, currentCategoryId, changeItemInfo } =
    useContext(ListsContext);
  const { setActiveEdit } = useContext(CooperationContext);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(currentItem.link || "");
  }, [currentItem]);

  return (
    <dialog id="item_link_modal" className="modal">
      <form method="dialog" className="modal-box">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
        <div className="flex flex-col gap-2 items-center">
          {t("create_list.add_link_to_this_item")}
          <input
            type="text"
            name="name"
            className="input input-bordered min-w-[12rem] w-full max-w-xs"
            placeholder={t("create_list.item_link") || ""}
            spellCheck="false"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={() =>
              setActiveEdit({
                type: "item",
                id: currentItem.id,
                fieldName: "type",
              })
            }
            onBlur={() =>
              setActiveEdit({
                type: "",
                id: "",
                fieldName: "",
              })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                changeItemInfo(
                  listId,
                  currentCategoryId,
                  currentItem.id,
                  "link",
                  inputValue
                );
              }
            }}
          />
          <button
            className="btn"
            onClick={() =>
              changeItemInfo(
                listId,
                currentCategoryId,
                currentItem.id,
                "link",
                inputValue
              )
            }
          >
            {t("common.save")}
          </button>
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
