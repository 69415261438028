import { Route, Routes, Link, Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import PasswordReset from "./PasswordReset";

export default function LandingPageForm() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="flex flex-col items-center bg-base-100 rounded-md shadow-md h-fit w-fit gap-4 p-4">
      <div className="flex gap-4">
        <Link
          to="/login"
          className={`btn btn-sm ${
            location.pathname === "/login" ? "btn-active" : ""
          }`}
        >
          {t("access.signin")}
        </Link>
        <Link
          to="/register"
          className={`btn btn-sm ${
            location.pathname === "/register" ? "btn-active" : ""
          }`}
        >
          {t("access.signup")}
        </Link>
      </div>
      <Routes>
        <Route path={`/login`} element={<Login />} />
        <Route path={`/register`} element={<Register />} />
        <Route path={`/forgot-password`} element={<ForgotPassword />} />
        <Route path={`/reset-password/:token`} element={<PasswordReset />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
      {/* <div className="divider">{t("common.or")}</div>
    <FacebookAuthButton />
    <GoogleAuthButton /> */}
    </div>
  );
}
