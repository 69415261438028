import { useContext } from "react";
import { ItemInterface } from "../Interfaces/ListInterface";
import { FavoriteItemsContext } from "../Contexts/FavoriteItemsContext";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";
import FavoriteItem from "./FavoriteItem";

export default function FavoriteItemsSection() {
  const { t } = useTranslation();

  const { favoriteItems, favoriteItemsAreLoading, addFavoriteItem } =
    useContext(FavoriteItemsContext);

  return favoriteItemsAreLoading ? (
    <Loading />
  ) : (
    <section className="flex flex-col items-center w-full gap-4 p-2 lg:p-8 overflow-auto">
      <h2 className="text-2xl font-bold self-center">
        {t("favorite_gear.header")}
      </h2>
      <div className="w-full border rounded border-base-300 overflow-auto">
        <table className="table">
          <thead>
            <tr>
              <td>{t("common.type")}</td>
              <td>{t("common.brand")}</td>
              <td>{t("common.name")}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{t("common.price")}</td>
              <td>{t("common.weight")}</td>
              <td>{t("common.quantity")}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {/* rows */}
            {favoriteItems.length === 0 ? (
              <tr>
                <td colSpan={6}>
                  Nie masz jeszcze żadnych ulubionych przedmiotów, kliknij
                  przycisk poniżej i utwórz pierwszy.
                </td>
              </tr>
            ) : (
              favoriteItems.map((item: ItemInterface) => {
                return <FavoriteItem key={item.id} item={item} />;
              })
            )}
          </tbody>
        </table>
      </div>
      <button
        onClick={() => addFavoriteItem()}
        className="btn flex-nowrap self-start"
      >
        <span className="material-symbols-outlined">add_circle</span>
        {t("favorite_gear.add_favorite_item")}
      </button>
    </section>
  );
}
