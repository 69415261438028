import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function PasswordReset() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { token } = useParams<{ token: string }>();

  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Reset error and success messages
    setError("");
    setSuccess("");

    if (password !== repeatPassword) {
      setError(`${t("access.password_mismatch")}`);
      return;
    }

    try {
      await axios.post(`${API_URL}/reset-password/${token}`, {
        password,
      });
      setSuccess(`${t("access.password_reset_successfully")}`);
    } catch (error: any) {
      if (error.response.status === 400) {
        setError(`${t("access.token_invalid_or_expired")}`);
      } else {
        setError(`${t("access.network_error")}`);
      }
    }
  };
  
  return (
    <div className="flex flex-col gap-4 items-center">
      {success && (
        <div className="alert alert-success">
          <span>{success}</span>
        </div>
      )}
      {error && (
        <div className="alert alert-error">
          <span>{error}</span>
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 items-center"
      >
        <h2 className="text-lg font-bold">
          {t("access.setting_new_passport")}
        </h2>
        <input
          type="password"
          placeholder={t("access.new_password") || ""}
          className="input input-bordered w-full"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          id="repeat-password"
          name="repeat-password"
          placeholder={t("access.repeat_new_password") || ""}
          className="input input-bordered w-full"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          required
        />
        <button type="submit" className="btn btn-accent w-full">
          {t("access.change_password")}
        </button>
      </form>
    </div>
  );
}
