import { useTranslation } from "react-i18next";

export default function TermsAndConditionsText() {
  const { t } = useTranslation();

  return (
    <div className="overflow-auto">
      <h1 className="text-3xl">
        {t("terms.terms_of_app")}{" "}
        <a className="link" href="https://hikepack.app/">
          hikepack.app
        </a>
      </h1>

      <h2 className="text-2xl mt-4">{t("terms.general_provisions")}</h2>
      <p>
        {t("terms.general_provisions_paragraph_1")} "
        <a className="link" href="https://hikepack.app/">
          hikepack.app
        </a>
        "{t("terms.general_provisions_paragraph_2")}
        <br />
        {t("terms.general_provisions_paragraph_3")}
        <br />
        {t("terms.general_provisions_paragraph_4")}
      </p>

      <h2 className="text-2xl mt-4">{t("terms.registriation_and_use")}</h2>
      <p>
        {t("terms.registriation_and_use_paragraph_1")}
        <br />
        {t("terms.registriation_and_use_paragraph_2")}
        <br />
        {t("terms.registriation_and_use_paragraph_3")}
      </p>

      <h2 className="text-2xl mt-4">{t("terms.user_responsibility")}</h2>
      <p>
        {t("terms.user_responsibility_paragpraph_1")}
        <br />
        {t("terms.user_responsibility_paragpraph_2")}
        <br />
        {t("terms.user_responsibility_paragpraph_3")}
      </p>

      <h2 className="text-2xl mt-4">
        {t("terms.admin_rights_and_resoponsibilities")}
      </h2>
      <p>
        {t("terms.admin_rights_and_resoponsibilities_paragpraph_1")}
        <br />
        {t("terms.admin_rights_and_resoponsibilities_paragpraph_2")}
        <br />
        {t("terms.admin_rights_and_resoponsibilities_paragpraph_3")}
        <br />
        {t("terms.admin_rights_and_resoponsibilities_paragpraph_4")}
        <br />
        {t("terms.admin_rights_and_resoponsibilities_paragpraph_5")}
      </p>

      <h2 className="text-2xl mt-4">{t("terms.minimal_tech_reqirements")}</h2>
      <p>{t("terms.minimal_tech_reqirements_paragraph_1")}</p>
      <p>
        {t("terms.minimal_tech_reqirements_paragraph_2")}
        <br />
        {t("terms.minimal_tech_reqirements_paragraph_3")}
        <br />
        {t("terms.minimal_tech_reqirements_paragraph_4")}
      </p>

      <h2 className="text-2xl mt-4">{t("terms.complaints_procedure")}</h2>
      <p>
        {t("terms.complaints_procedure_paragraph_1")}
        <br />
        {t("terms.complaints_procedure_paragraph_2")}
        <br />
        {t("terms.complaints_procedure_paragraph_3")}
      </p>

      <h2 className="text-2xl mt-4">{t("terms.termination_of_using")}</h2>
      <p>
        {t("terms.termination_of_using_paragraph_1")}
        <br />
        {t("terms.termination_of_using_paragraph_2")}
      </p>

      <h2 className="text-2xl mt-4">
        {t("terms.protection_of_personal_data")}
      </h2>
      <p>
        {t("terms.protection_of_personal_data_paragraph_1")}
        <br />
        {t("terms.protection_of_personal_data_paragraph_2")}
        <br />
        {t("terms.protection_of_personal_data_paragraph_3")}{" "}
        <a
          className="link"
          href="https://hikepack.app/privacy"
          target="_blank"
          rel="noreferrer"
        >
          {t("common.privacy_policy_2")}
        </a>{" "}
        {t("terms.protection_of_personal_data_paragraph_4")}
      </p>

      <h2 className="text-2xl mt-4">{t("terms.final_provisions")}</h2>
      <p>
        {t("terms.final_provisions_paragraph_1")}
        <br />
        {t("terms.final_provisions_paragraph_2")}
        <br />
        {t("terms.final_provisions_paragraph_3")}
        <br />
        {t("terms.final_provisions_paragraph_4")}
      </p>
    </div>
  );
}
