import { useState, useEffect, useContext } from "react";
import { ListsContext } from "../Contexts/ListsContext";
import ListNameAndDescSection from "./ListNameAndDescSection";
import ListSettingsSection from "./ListSettingsSection";
import ListBody from "./ListBody";
import ChartAndSummarySection from "./ChartAndSummarySection";
import FavoriteItemsModal from "../Modals/FavoriteItemsModal";
import GlobalItemsModal from "../Modals/GlobalItemsModal";
import Loading from "./Loading";

export default function CreateListSection() {
  const { listsAreLoading } = useContext(ListsContext);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (!listsAreLoading) {
      setIsInitialLoad(false);
    }
  }, [listsAreLoading]);

  return (
    <>
      {listsAreLoading && isInitialLoad ? (
        <Loading />
      ) : (
        <div className="flex flex-col w-full p-2 lg:p-8 overflow-auto">
          <div className="flex flex-col lg:flex-row gap-2 items-center border-b border-base-200">
            <ListNameAndDescSection />
            <ListSettingsSection />
          </div>
          <ChartAndSummarySection />
          <ListBody />
          <FavoriteItemsModal />
          <GlobalItemsModal />
        </div>
      )}
    </>
  );
}
