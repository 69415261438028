import { useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA4 from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { ListsContext } from "../Contexts/ListsContext";
import { ListInterface } from "../Interfaces/ListInterface";
import CooperationSettings from "../Components/CooperationSettings";
import { CooperationContext } from "../Contexts/CooperationContext";
import { AuthContext } from "../Contexts/AuthContext";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { getCookie } from "../Utilities/getCookie";

export default function SettingsModal() {
  const { t } = useTranslation();

  const { listId } = useParams();

  const { isListOwner, userDetails } = useContext(AuthContext);

  const { lists, updateListSettings } = useContext(ListsContext);
  const { setActiveEdit } = useContext(CooperationContext);

  const currentList = lists.find(
    (currentList: ListInterface) => currentList.id === listId
  );

  const handleCooperationChange = (event: any) => {
    updateListSettings(listId, "cooperation", event.target.checked);

    if (event.target.checked) {
      ReactPixel.trackCustom("TurnOnCooperation");

      ReactGA4.event("turn_on_cooperation", {
        name: "turn_on_cooperation",
        label: "Turn On Cooperation",
        list_id: currentList.id,
      });

      // Send to backend
      sendEventToBackend(
        "TurnOnCooperation",
        window.location.href,
        "website",
        userDetails?.email,
        getCookie("_fbp") || "",
        getCookie("_fbc") || ""
      );
    }
  };

  return (
    <dialog id="settings_modal" className="modal">
      <form method="dialog" className="modal-box">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
        <div className="flex flex-col">
          <div className="form-control w-fit">
            <label className="label cursor-pointer gap-1">
              <input
                type="checkbox"
                name="items-price"
                className="checkbox"
                value="items-price"
                checked={currentList?.settings.showPrice}
                onChange={(event) =>
                  updateListSettings(listId, "showPrice", event.target.checked)
                }
                onFocus={() =>
                  setActiveEdit({
                    type: "list",
                    id: listId,
                    fieldName: "settings.showPrice",
                  })
                }
                onBlur={() =>
                  setActiveEdit({
                    type: "",
                    id: "",
                    fieldName: "",
                  })
                }
              />
              <span className="label-text text-base">
                {t("create_list.settings.items_price")}
              </span>
            </label>
          </div>
          {currentList?.settings.showPrice && (
            <div className="flex gap-1 items-center">
              {/* Currency select */}
              <label>{t("common.currency")}</label>
              <select
                value={currentList?.settings.currency || ""}
                className="select w-fit max-w-fit"
                onChange={(event) =>
                  updateListSettings(listId, "currency", event.target.value)
                }
                onFocus={() =>
                  setActiveEdit({
                    type: "list",
                    id: listId,
                    fieldName: "settings.currency",
                  })
                }
                onBlur={() =>
                  setActiveEdit({
                    type: "",
                    id: "",
                    fieldName: "",
                  })
                }
              >
                <option value="" disabled>
                  {t("create_list.settings.choose_currency")}
                </option>
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
                <option value="PLN">PLN</option>
                <option value="GBP">GBP</option>
              </select>
            </div>
          )}
          <div className="form-control w-fit">
            <label className="label cursor-pointer gap-1">
              <input
                type="checkbox"
                name="checklist"
                className="checkbox"
                value="checklist"
                checked={currentList?.settings.checklist}
                onChange={(event) =>
                  updateListSettings(listId, "checklist", event.target.checked)
                }
                onFocus={() =>
                  setActiveEdit({
                    type: "list",
                    id: listId,
                    fieldName: "settings.checklist",
                  })
                }
                onBlur={() =>
                  setActiveEdit({
                    type: "",
                    id: "",
                    fieldName: "",
                  })
                }
              />
              <span className="label-text text-base">
                {t("create_list.settings.checklist")}
              </span>
            </label>
          </div>
          <div className="form-control w-fit">
            <label className="label cursor-pointer gap-1">
              <input
                type="checkbox"
                name="consumable-items"
                className="checkbox"
                value="consumable-items"
                checked={currentList?.settings.consumableItems}
                onChange={(event) =>
                  updateListSettings(
                    listId,
                    "consumableItems",
                    event.target.checked
                  )
                }
                onFocus={() =>
                  setActiveEdit({
                    type: "list",
                    id: listId,
                    fieldName: "settings.consumableItems",
                  })
                }
                onBlur={() =>
                  setActiveEdit({
                    type: "",
                    id: "",
                    fieldName: "",
                  })
                }
              />
              <span className="label-text text-base">
                {t("create_list.settings.consumable_items")}
              </span>
            </label>
          </div>
          <div className="form-control w-fit">
            <label className="label cursor-pointer gap-1">
              <input
                type="checkbox"
                name="worn-items"
                className="checkbox"
                value="worn-items"
                checked={currentList?.settings.wornItems}
                onChange={(event) =>
                  updateListSettings(listId, "wornItems", event.target.checked)
                }
                onFocus={() =>
                  setActiveEdit({
                    type: "list",
                    id: listId,
                    fieldName: "settings.wornItems",
                  })
                }
                onBlur={() =>
                  setActiveEdit({
                    type: "",
                    id: "",
                    fieldName: "",
                  })
                }
              />
              <span className="label-text text-base">
                {t("create_list.settings.worn_items")}
              </span>
            </label>
          </div>
          <div className="form-control w-fit">
            <label className="label cursor-pointer gap-1">
              <input
                type="checkbox"
                name="cooperation"
                className="checkbox"
                value="cooperation"
                checked={currentList?.settings.cooperation}
                onChange={(event) =>
                  isListOwner // Only allow owners to change this setting
                    ? handleCooperationChange(event)
                    : null
                }
                onFocus={() =>
                  setActiveEdit({
                    type: "list",
                    id: listId,
                    fieldName: "settings.cooperation",
                  })
                }
                onBlur={() =>
                  setActiveEdit({
                    type: "",
                    id: "",
                    fieldName: "",
                  })
                }
              />
              <span className="label-text text-base flex gap-2">
                {t("create_list.settings.cooperation")}
                <span className="material-symbols-outlined">group</span>
                <span className="text-xs align-text-top">BETA</span>
              </span>
            </label>
          </div>
          {listId && currentList?.settings.cooperation && (
            <CooperationSettings listId={listId} />
          )}
        </div>
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
