import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="h-fit w-full flex flex-col items-center pt-8 pb-4 bg-footer text-gray-100">
      <Link
        to="/register"
        className="btn btn-accent mb-8"
        onClick={handleScroll}
      >
        {t("landing_page.dont_wait_register")}
      </Link>
      <div className="flex flex-col items-center pb-8">
        <h2>{t("landing_page.join_our_community_on_fb")}</h2>
        <a href="https://www.facebook.com/groups/246571195015758/" target="_blank" rel="noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="3em"
            viewBox="0 0 512 512"
          >
            <path
              fill="#FFFFFF"
              d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
            />
          </svg>
        </a>
      </div>
      <div className="flex gap-4">
        <button
          className="link"
          onClick={() => window.terms_and_conditions_modal.showModal()}
        >
          {t("common.terms_and_conditions")}
        </button>
        <button
          className="link"
          onClick={() => window.privacy_policy_modal.showModal()}
        >
          {t("common.privacy_policy")}
        </button>
      </div>
      <p>
        {t("landing_page.made_with")} <span className="text-[#FF0000]">❤</span>{" "}
        {t("landing_page.to_travel")}
      </p>
    </div>
  );
}
