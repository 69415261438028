import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const sendEventToBackend = (
  eventName: any,
  location: any,
  sourceOfAction: string,
  userEmail: string | undefined,
  fbp: string | undefined,
  fbc: string | undefined,
  customData = {}
) => {
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  const eventData = {
    event_name: eventName,
    event_time: Math.floor(Date.now() / 1000), // Current time in seconds
    action_source: sourceOfAction,
    event_source_url: location, // Full URL
    user_email: userEmail,
    fbp, // Include Facebook Pixel browser identifier
    fbc, // Include Facebook Click identifier
    custom_data: {
      path: location.pathname,
      ...customData, // Allow additional custom data to be merged.
    },
  };

  // Send the conversion event to our backend which will then send it to Facebook
  axios
    .post(`${API_URL}/meta/track-conversion`, eventData)
    .catch((error) =>
      console.error(`Failed to send ${eventName} to backend:`, error)
    );
};
