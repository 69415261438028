import TermsAndConditionsText from "../Components/TermsAndConditionsText";

export default function TermsAndConditionsModal() {
  return (
    <dialog id="terms_and_conditions_modal" className="modal">
      <form method="dialog" className="modal-box w-11/12 max-w-3xl">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
        <TermsAndConditionsText />
      </form>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
