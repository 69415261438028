import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { ListsContext } from "../Contexts/ListsContext";
import { ListInterface } from "../Interfaces/ListInterface";
import { useTranslation } from "react-i18next";
import Summary from "./Summary";
import Chart from "./Chart";
import PackingProgressBar from "./PackingProgressBar";
import {
  calculateListTotalPrice,
  calculateListTotalWeight,
} from "../Utilities/calculateTotals";

export default function ChartAndSummarySection() {
  const { t } = useTranslation();

  const { listId } = useParams();

  const { lists } = useContext(ListsContext);

  const currentList = lists.find(
    (currentList: ListInterface) => currentList.id === listId
  );

  const showProgressBar = currentList ? currentList.settings.checklist : false;

  const totalWeight = calculateListTotalWeight(currentList);
  const totalPrice = calculateListTotalPrice(currentList);

  return (
    <div className="flex flex-col items-center mt-2 lg:grid lg:grid-cols-2 gap-4">
      {totalWeight || totalPrice > 0 ? (
        <>
          <Chart currentList={currentList} />
          <Summary currentList={currentList} setCurrentList={null} forGuest={false} />
        </>
      ) : (
        <div className="flex flex-col gap-4 mt-2">
          <h2 className="text-xl font-bold">
            {t("quick_instruction.header_1")}
          </h2>
          <h3 className="text-lg mb-[-1rem]">
            {t("quick_instruction.header_2")}
          </h3>
          <ol className="list-decimal ml-4">
            <li>{t("quick_instruction.paragraph_1")}</li>
            <li>{t("quick_instruction.paragraph_2")}</li>
            <li>{t("quick_instruction.paragraph_3")}</li>
            <li>{t("quick_instruction.paragraph_4")}</li>
          </ol>
          <p>
            {t("quick_instruction.paragraph_5")} "
            <Link to="/help">
              <span className="link">{t("quick_instruction.link_1")}</span>
            </Link>
            ".
          </p>
        </div>
      )}
      {showProgressBar ? (
        <PackingProgressBar currentList={currentList} />
      ) : null}
    </div>
  );
}
