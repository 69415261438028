import {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
  useRef,
} from "react";
import { AuthContext } from "./AuthContext";
import { ListsContext } from "./ListsContext";
import {
  ListInterface,
  CategoryInterface,
  ItemInterface,
} from "../Interfaces/ListInterface";

export const CooperationContext = createContext<any>(null);

export const CooperationProvider = ({ children }: { children: ReactNode }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const { authenticated } = useContext(AuthContext);
  const { lists, setLists } = useContext(ListsContext);

  const [cooperationEnabled, setCooperationEnabled] = useState(false);
  const [activeEdit, setActiveEdit] = useState({
    type: "", // Can be 'list', 'category', or 'item'
    id: "", // Will hold the corresponding id
    fieldName: "", // Field name, for example, 'name', 'description', 'price', etc.
  });

  const INITIAL_RECONNECT_DELAY_MS = 5000; // 5 seconds
  const MAX_RECONNECT_DELAY_MS = 1800000; // 30 minutes
  const [reconnectDelay, setReconnectDelay] = useState(
    INITIAL_RECONNECT_DELAY_MS
  );

  useEffect(() => {
    if (lists.some((list: ListInterface) => list.settings.cooperation)) {
      setCooperationEnabled(true);
    } else {
      setCooperationEnabled(false);
    }
  }, [lists]);

  const activeEditRef = useRef(activeEdit);

  useEffect(() => {
    activeEditRef.current = activeEdit;
  }, [activeEdit]);

  // SSE
  useEffect(() => {
    if (!authenticated || !cooperationEnabled) return;

    let isMounted = true;

    const eventSource = new EventSource(`${API_URL}/lists/realtime-updates`, {
      withCredentials: true,
    });

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      eventSource.close();

      // Check if reconnect delay has not reached the maximum limit
      if (reconnectDelay < MAX_RECONNECT_DELAY_MS) {
        setTimeout(() => {
          setReconnectDelay((prev) =>
            Math.min(prev * 2, MAX_RECONNECT_DELAY_MS)
          );
        }, reconnectDelay);
      }
    };

    eventSource.onmessage = (event) => {
      if (event.data === "PING") {
        // Just return.
        return;
      }

      const update = JSON.parse(event.data);

      const currentActiveEdit = activeEditRef.current;

      if (update.type === "LIST_UPDATE" && isMounted) {
        setLists((prevLists: any) => {
          const index = prevLists.findIndex(
            (list: ListInterface) => list.id === update.listId
          );

          if (
            index !== -1 &&
            new Date(update.lastUpdated) >
              new Date(prevLists[index].lastUpdated)
          ) {
            const newLists = [...prevLists];

            // Handle active list edits
            if (
              currentActiveEdit.type === "list" &&
              currentActiveEdit.id === update.listId
            ) {
              if (currentActiveEdit.fieldName.startsWith("settings.")) {
                const settingName = currentActiveEdit.fieldName.split(".")[1];
                if (update.data.settings && newLists[index].settings) {
                  update.data.settings[settingName] =
                    newLists[index].settings[settingName];
                }
              } else {
                update.data[currentActiveEdit.fieldName] =
                  newLists[index][currentActiveEdit.fieldName];
              }
            }

            // Handle active category edits
            if (
              currentActiveEdit.type === "category" &&
              currentActiveEdit.id &&
              newLists[index].categories
            ) {
              const categoryIndex = newLists[index].categories.findIndex(
                (category: CategoryInterface) =>
                  category.id === currentActiveEdit.id
              );
              if (categoryIndex !== -1) {
                update.data.categories[categoryIndex][
                  currentActiveEdit.fieldName
                ] =
                  newLists[index].categories[categoryIndex][
                    currentActiveEdit.fieldName
                  ];
              }
            }

            // Handle active item edits
            if (currentActiveEdit.type === "item" && currentActiveEdit.id) {
              const categoriesToUpdate = update.data.categories || [];
              categoriesToUpdate.forEach((category: CategoryInterface) => {
                const itemIndex = category.items.findIndex(
                  (item: ItemInterface) => item.id === currentActiveEdit.id
                );
                if (itemIndex !== -1) {
                  category.items[itemIndex][currentActiveEdit.fieldName] =
                    newLists[index].categories?.find(
                      (cat: any) => cat.id === category.id
                    )?.items[itemIndex][currentActiveEdit.fieldName];
                }
              });
            }

            newLists[index] = {
              ...newLists[index],
              lastUpdated: update.lastUpdated,
            };

            return newLists;
          }
          return prevLists;
        });
      }
    };

    return () => {
      isMounted = false;
      eventSource.close(); // Don't forget to close the event source connection
    };
  }, [API_URL, authenticated, setLists, cooperationEnabled, reconnectDelay]);

  return (
    <CooperationContext.Provider value={{ setActiveEdit }}>
      {children}
    </CooperationContext.Provider>
  );
};
