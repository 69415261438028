import { useTranslation } from "react-i18next";

export default function Loading() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-screen w-screen gap-2">
      <span className="loading loading-spinner text-success"></span>
      <span>{t("common.loading")}</span>
      <span>
        {t("common.if_loading_too_long")}
        <a className="link" href="https://hikepack.app/">HikePack.app</a>
      </span>
    </div>
  );
}
