import { useTranslation } from "react-i18next";
import logo from "../Assets/logo/logo.png";

export default function ListIsPrivateError() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 items-center justify-center w-full">
      <a href="https://hikepack.app/" target="_blank" rel="noreferrer">
        <img src={logo} alt="HikePack logo" className="h-24 lg:h-24 mt-1" />
      </a>
      <p className="text-center">
        {t("error.this_list_is_private_you_cant_see_it")}
        <br />
        {t("error.if_you_think_otherwise_contact_list_owner")}
      </p>
      <p className="divider w-64 ml-auto mr-auto">{t("common.or")}</p>
      <a href="https://hikepack.app/" className="btn btn-accent">
        {t("create_list.create_your_own_list")}
      </a>
    </div>
  );
}
