import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";
import { AuthContext } from "../Contexts/AuthContext";
import { AlertContext } from "../Contexts/AlertContext";
import { sendEventToBackend } from "../Utilities/sendEventToBackend";
import { getCookie } from "../Utilities/getCookie";

export default function UserPanel() {
  const API_URL = process.env.REACT_APP_API_URL;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [currentPassword, setCurrentPasword] = useState("");
  const [newPassword, setNewPasword] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [repeatNewPassword, setRepeatNewPasword] = useState("");
  const [passwordForDelete, setPasswordForDelete] = useState("");
  const [deleteAccountError, setDeleteAccountError] = useState("");
  const [confirmationForDelete, setConfirmationForDelete] = useState("");
  const [wantDeleteAccount, setWantDeleteAccount] = useState(false);
  const [deleteAccountCheckbox, setDeleteAccountCheckbox] = useState(false);
  const [changeUsernameSuccess, setChangeUsernameSuccess] = useState("");
  const [changeUsernameError, setChangeUsernameError] = useState("");
  const [changePasswordSuccess, setChangePasswordSuccess] = useState("");
  const [changePasswordError, setChangePasswordError] = useState("");

  const { userDetails, setAuthenticated } = useContext(AuthContext);
  const { displayAlert } = useContext(AlertContext);

  const handleChangeUsernameFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    try {
      await axios.post(
        `${API_URL}/change-username`,
        {
          email: userDetails.email,
          newUsername: newUserName,
        },
        {
          withCredentials: true,
        }
      );

      setChangeUsernameError("");
      setChangeUsernameSuccess(
        `${t("user_panel.username_changed_successfully")}`
      );
    } catch (error: any) {
      if (error.response.status === 409) {
        setChangeUsernameError(`${t("user_panel.username_taken")}`);
      } else {
        setChangeUsernameError(`${t("user_panel.username_change_error")}`);
      }
    }
  };

  const handleChangePasswordFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    // Validation: Ensure new passwords match
    if (newPassword !== repeatNewPassword) {
      setChangePasswordError(`${t("user_panel.password_mismatch")}`);
      return;
    }

    try {
      // Validate current password
      const validationResponse = await axios.post(
        `${API_URL}/validate-password`,
        {
          email: userDetails.email,
          password: currentPassword,
        },
        {
          withCredentials: true,
        }
      );

      if (
        validationResponse.data.message === "Password validation successful."
      ) {
        // Update to new password
        await axios.post(
          `${API_URL}/change-password`,
          {
            email: userDetails.email,
            newPassword: newPassword,
          },
          {
            withCredentials: true,
          }
        );

        setChangePasswordError("");
        setChangePasswordSuccess(
          `${t("user_panel.password_changed_successfully")}`
        );
      }
    } catch (error: any) {
      if (error.response.status === 403) {
        setChangePasswordError(`${t("user_panel.wrong_current_password")}`);
      } else {
        setChangePasswordError(`${t("user_panel.error_changing_password")}`);
      }
    }
  };

  const handleDeleteAccount = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validation for correct confirmation
    if (confirmationForDelete !== "Bye HikePack") {
      setDeleteAccountError(`${t("user_panel.error_deleting_account")}`);
      return;
    }

    // Validation for checkbox being checked
    if (!deleteAccountCheckbox) {
      setDeleteAccountError(`${t("user_panel.error_deleting_account")}`);
      return;
    }

    try {
      // Validate current password
      const validationResponse = await axios.post(
        `${API_URL}/validate-password`,
        {
          email: userDetails.email,
          password: passwordForDelete,
        },
        {
          withCredentials: true,
        }
      );

      if (
        validationResponse.data.message === "Password validation successful."
      ) {
        // Delete user
        await axios
          .delete(`${API_URL}/user/${userDetails.id}`, {
            withCredentials: true,
          })
          .then(() => {
            setAuthenticated(false);
            navigate("/login");
            displayAlert("alert.account_deleted_successfully", "success");

            ReactPixel.trackCustom("DeleteAccount");

            ReactGA4.event("delete_account", {
              name: "delete_account",
              label: "Delete Account",
            });

            // Send the event to the backend
            sendEventToBackend(
              "DeleteAccount",
              window.location.href,
              "website",
              userDetails?.email,
              getCookie("_fbp") || "",
              getCookie("_fbc") || ""
            );
          })
          .catch((error) => {
            console.error("Error logging out:", error);
          });
      }
    } catch (error) {
      console.error(error);
      setDeleteAccountError(`${t("user_panel.error_deleting_account")}`);
    }
  };

  return (
    <div className="flex flex-col items-center gap-4 lg:flex-row lg:items-start lg:justify-start w-full h-full p-2 lg:p-8">
      <section className="flex flex-col items-center justify-center gap-2 w-full lg:w-96 p-4 border rounded border-base-200 border-solid">
        <h1 className="text-2xl">{t("user_panel.username_change")}</h1>

        <form
          className="flex flex-col items-center justify-center w-full gap-4"
          onSubmit={handleChangeUsernameFormSubmit}
        >
          <input
            className="input input-bordered w-full max-w-xs"
            type="text"
            name="current-password"
            value={newUserName}
            placeholder={t("user_panel.new_username") || ""}
            maxLength={32}
            onChange={(e) => setNewUserName(e.target.value)}
          />
          <button className="btn" type="submit">
            {t("user_panel.change_username")}
          </button>
        </form>
        {changeUsernameSuccess && (
          <div className="alert alert-success">
            <span>{changeUsernameSuccess}</span>
          </div>
        )}
        {changeUsernameError && (
          <div className="alert alert-error">
            <span>{changeUsernameError}</span>
          </div>
        )}
      </section>
      <section className="flex flex-col items-center justify-center gap-2 w-full lg:w-96 p-4 border rounded border-base-200 border-solid">
        <h1 className="text-2xl">{t("user_panel.password_change")}</h1>
        <form
          className="flex flex-col items-center justify-center w-full gap-4"
          onSubmit={handleChangePasswordFormSubmit}
        >
          <input
            className="input input-bordered w-full max-w-xs"
            type="password"
            name="current-password"
            value={currentPassword}
            placeholder={t("user_panel.current_password") || ""}
            onChange={(e) => setCurrentPasword(e.target.value)}
          />
          <input
            className="input input-bordered w-full max-w-xs"
            type="password"
            name="new-password"
            value={newPassword}
            placeholder={t("user_panel.new_password") || ""}
            onChange={(e) => setNewPasword(e.target.value)}
          />
          <input
            className="input input-bordered w-full max-w-xs"
            type="password"
            name="repeat-new-password"
            value={repeatNewPassword}
            placeholder={t("user_panel.repeat_new_password") || ""}
            onChange={(e) => setRepeatNewPasword(e.target.value)}
          />
          <button className="btn" type="submit">
            {t("user_panel.change_password")}
          </button>
        </form>
        {changePasswordSuccess && (
          <div className="alert alert-success">
            <span>{changePasswordSuccess}</span>
          </div>
        )}
        {changePasswordError && (
          <div className="alert alert-error">
            <span>{changePasswordError}</span>
          </div>
        )}
      </section>
      <section className="flex flex-col items-center justify-center gap-2 w-full lg:w-96 p-4 border rounded border-base-200 border-solid">
        <h1 className="text-2xl">{t("user_panel.delete_account")}</h1>
        <div className="form-control">
          <label className="cursor-pointer label gap-2">
            <input
              type="checkbox"
              checked={wantDeleteAccount}
              className="checkbox"
              onChange={() => setWantDeleteAccount(!wantDeleteAccount)}
            />
            <span className="label-text">
              {t("user_panel.i_want_to_delete_account")}
            </span>
          </label>
        </div>

        {wantDeleteAccount && (
          <form
            className="flex flex-col items-center justify-center w-full gap-4"
            onSubmit={handleDeleteAccount}
          >
            <input
              className="input input-bordered w-full max-w-xs"
              type="password"
              name="password"
              value={passwordForDelete}
              placeholder={t("user_panel.your_password") || ""}
              onChange={(e) => setPasswordForDelete(e.target.value)}
            />
            <p>
              {t("user_panel.write_in_field_below")}
              <strong>{t("user_panel.bye_hikepack")}</strong>
            </p>
            <input
              className="input input-bordered w-full max-w-xs"
              type="text"
              name="password"
              value={confirmationForDelete}
              placeholder={t("user_panel.write_here_bye_hikepack") || ""}
              onChange={(e) => setConfirmationForDelete(e.target.value)}
            />
            <div className="form-control">
              <label className="cursor-pointer label gap-2">
                <input
                  type="checkbox"
                  checked={deleteAccountCheckbox}
                  className="checkbox checkbox-error"
                  onChange={() =>
                    setDeleteAccountCheckbox(!deleteAccountCheckbox)
                  }
                />
                <span className="label-text">
                  {t(
                    "user_panel.i_want_to_delete_account_and_i_know_that_this_cant_be_undone"
                  )}
                </span>
              </label>
            </div>
            <button className="btn" type="submit">
              {t("user_panel.delete_my_account_permanently")}
            </button>
          </form>
        )}
        {deleteAccountError && (
          <div className="alert alert-error">
            <span>{deleteAccountError}</span>
          </div>
        )}
      </section>
    </div>
  );
}
