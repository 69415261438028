const languageToDomainMap: { [key: string]: string } = {
  pl: ".pl",
  fr: ".fr",
  de: ".de",
  es: ".es",
  jp: ".co.jp",
  default: ".com",
};

export const generateAmazonUrl = (
  itemType: string,
  itemBrand: string,
  itemName: string,
  language: string
) => {
  const domain =
    languageToDomainMap[language] || languageToDomainMap["default"];
  const baseUrl = `https://www.amazon${domain}/gp/search?ie=UTF8&tag=janekk098-21&keywords=`;

  const keywords = [itemType, itemBrand, itemName].filter(Boolean).join(" ");

  return baseUrl + encodeURIComponent(keywords);
};
