import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function ContactSection() {
  const { t } = useTranslation();

  const API_URL = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}/submit-contact`, formData);
      if (response.status === 200) {
        setSuccess(`${t("contact.email_sent_successfully")}`);
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        setError(`${t("contact.error_sending_email")}`);
      }
    } catch (error) {
      console.error("Error:", error);
      setError(`${t("contact.error_sending_email")}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-2 lg:p-0">
      <h2 className="text-2xl font-bold mb-2">{t("contact.contact_us")}</h2>
      <div className="flex flex-col justify-center items-center w-full md:w-1/3 gap-2">
        <p>{t("contact.contact_paragraph")}</p>
        <form
          className="flex flex-col justify-center w-full gap-2"
          onSubmit={handleSubmit}
        >
          <div>
            <input
              type="text"
              name="name"
              placeholder={t("contact.your_name") || ""}
              className="input input-bordered w-full max-w-xs"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder={t("contact.your_email") || ""}
              className="input input-bordered w-full max-w-xs"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <textarea
              name="message"
              placeholder={t("contact.your_message") || ""}
              className="textarea textarea-bordered w-full h-32"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          {success && (
            <div className="alert alert-success">
              <span>{success}</span>
            </div>
          )}
          {error && (
            <div className="alert alert-error">
              <span>{error}</span>
            </div>
          )}
          <button
            className="btn btn-accent w-full max-w-xs self-center"
            type="submit"
          >
            {loading ? (
              <div className="flex gap-2 justify-center items-center">
                <span className="loading loading-spinner text-success"></span>
                <span>{t("common.loading")}</span>
              </div>
            ) : (
              <div>{t("contact.send")}</div>
            )}
          </button>
        </form>
        <div className="divider">{t("common.or")}</div>
        <p>{t("contact.or_write_us_email")}</p>
      </div>
    </div>
  );
}
